import React, { useContext, useCallback, useState } from 'react';
import { app, auth } from '../base';
import { message, notification, Form, Input, Button, Card, Row, Col } from 'antd';
import logo from '../images/logo.png';
import { AuthContext } from '../Auth';
import { MailOutlined, WarningOutlined, LoadingOutlined } from '@ant-design/icons';
import { signInWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';
import { useNavigate } from 'react-router';
import blue from '../images/blue.jpg';
import logowhite from '../images/white.png';

const Login: React.FC = () => {
    const navigate = useNavigate();
    const [status, setStatus] = useState(false);
    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const handleLogin = useCallback(
        async values => {
            try {
                await signInWithEmailAndPassword(auth, values.username, values.password).then(
                    () => {
                        let user = auth.currentUser;
                        // if (user && user.emailVerified === false) {
                        //     notification.open({
                        //         message: 'Please verify your email',
                        //         duration: 10,
                        //         description: (
                        //             <div>
                        //                 <p>
                        //                     Email verification is required to keep your account
                        //                     secure
                        //                 </p>
                        //                 <Button
                        //                     type='primary'
                        //                     onClick={() => {
                        //                         let currentUser = auth.currentUser;

                        //                         currentUser &&
                        //                             sendEmailVerification(currentUser).then(() => {
                        //                                 message.info('Please check your email');
                        //                             });
                        //                     }}
                        //                 >
                        //                     Send Email
                        //                     <MailOutlined />
                        //                 </Button>
                        //             </div>
                        //         ),
                        //         icon: <WarningOutlined />
                        //     });
                        // }
                    }
                );
                setStatus(false);
            } catch (error) {
                setStatus(false);
                try {
                    const errorCode = (error as any).code;
                    if (errorCode === 'auth/wrong-password') {
                        message.error('Incorrect Password');
                    } else if (errorCode === 'auth/user-not-found') {
                        message.error('User not found');
                    } else {
                        console.log(errorCode);
                    }
                } catch (e) {
                    message.error('Something went wrong!');
                }
            }
        },
        [navigate]
    );

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const { currentUser } = useContext(AuthContext);

    if (currentUser) {
        navigate('/');
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#F7F7F7'
            }}
        >
            <Row style={{ height: '43%' }}>
                <Col
                    lg={{ span: 12 }}
                    xs={{ span: 0 }}
                    style={{
                        backgroundImage: `url(${blue})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        color: 'white'
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'rgba(24,144,255, 0.8)',
                            padding: 40,
                            height: '100%'
                        }}
                    >
                        <Row>
                            <Col>
                                <img src={logowhite} height={60} />
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 70 }}>
                            <h1 style={{ color: 'white' }}>Welcome back!</h1>
                        </Row>
                        <Row>
                            <span>
                                We are glad to see you again. Build strategies and discover alpha
                                without risking capital.
                            </span>
                        </Row>
                    </div>
                </Col>
                <Col
                    lg={{ span: 12 }}
                    xs={{ span: 24, offset: 0 }}
                    style={{
                        padding: 45,
                        backgroundColor: 'white'
                    }}
                >
                    <Form
                        name='basic'
                        initialValues={{ remember: true }}
                        onFinish={handleLogin}
                        onFinishFailed={onFinishFailed}
                        autoComplete='off'
                    >
                        <Form.Item>
                            {/* <img src={logo} height={60} /> */}
                            <h2>Log in to Fulcher Analytics</h2>
                        </Form.Item>
                        <small>Email</small>
                        <Form.Item
                            name='username'
                            rules={[{ required: true, message: 'Please input your email!' }]}
                            style={{ width: 400 }}
                        >
                            <Input type='email' width={200} />
                        </Form.Item>

                        <small>Password</small>
                        <Form.Item
                            name='password'
                            rules={[{ required: true, message: 'Please input your password!' }]}
                            style={{ width: 400 }}
                        >
                            <Input.Password width={200} />
                        </Form.Item>

                        <small>
                            <a href='/forgot-password'>Forgot password?</a>
                        </small>
                        <br />
                        <small>
                            <a href='/register'>Don't have an account?</a>
                        </small>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{ marginTop: 30 }}
                                onClick={() => setStatus(true)}
                            >
                                Sign In {status && <LoadingOutlined />}
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default Login;
