import React, { useState } from 'react';
import { Row, Col, Tag, message } from 'antd';
import { DownOutlined, RightOutlined, PlusOutlined } from '@ant-design/icons';
import { triggers as t, Trigger, TriggerDomain } from '../triggers';
import TriggerAddDropDown from './TriggerAddDropDown';
import TriggerList from './TriggerList';
import AssetSelectionModal from './AssetSelectionModal';

const triggers = [...t];
const cnameMatch = {
    ethusd: 'Ethereum',
    btcusd: 'Bitcoin',
    xrpusd: 'Ripple',
    adausd: 'Cardano',
    solusd: 'Solana',
    dogeusd: 'Dogecoin',
    maticusd: 'Polygon',
    ltcusd: 'Litecoin'
};
interface CollapsbileSectionProps {
    open: boolean;
    setOpen: (state: boolean) => void;
    title: string;
    setData: (triggers: any) => void;
    data: Array<Trigger>;
    modifier: 'or' | 'and';
    setModifier: (modifier: 'or' | 'and') => void;
    userStatus?: string;
    readonly?: boolean;
}

const CollapsibleSection = (props: CollapsbileSectionProps) => {
    const { open, setOpen, title, data, setData, setModifier, modifier, userStatus } = props;

    const [searchString, setSearchString] = useState<string>('');
    const [assetSelectionVisible, setAssetSelectionVisible] = useState<boolean>(false);

    const addTrigger = (trigger?: Trigger) => {
        let newTrigger;
        if (!trigger) {
            let filteredTriggers = triggers.filter((item: Trigger) =>
                item.title.toLowerCase().includes(searchString.toLowerCase())
            );
            if (filteredTriggers.length) {
                newTrigger = data.length
                    ? { ...filteredTriggers[0], modifier: props.modifier }
                    : filteredTriggers[0];
            } else {
                return;
            }
        } else {
            newTrigger = data.length ? { ...trigger, modifier: props.modifier } : trigger;
        }
        if (newTrigger.domain === TriggerDomain.Exit && title.includes('Entry')) {
            message.warning('This trigger is only available as an exit condition');
            return;
        }
        setData([...data, newTrigger]);
    };

    return (
        <div
            style={{
                backgroundColor: '#F4F5F7',
                padding: 8,
                marginTop: 10,
                borderRadius: 8,
                paddingLeft: 12,
                userSelect: 'none'
            }}
        >
            {/* <AssetSelectionModal
                readonly={props.readonly}
                visible={assetSelectionVisible}
                onClose={() => setAssetSelectionVisible(false)}
                asset={asset}
                setAsset={setAsset}
                userStatus={userStatus}
            /> */}
            <Row style={{ paddingBottom: 10 }}>
                <span style={{ fontWeight: 700, cursor: 'pointer' }} onClick={() => setOpen(!open)}>
                    {open ? (
                        <DownOutlined style={{ fontSize: 12, fontWeight: 700, marginRight: 6 }} />
                    ) : (
                        <RightOutlined style={{ fontSize: 12, fontWeight: 700, marginRight: 6 }} />
                    )}
                    {title}{' '}
                </span>
            </Row>
            {open && (
                <>
                    <TriggerList
                        readonly={props.readonly}
                        selectedTriggers={data}
                        setSelectedTriggers={setData}
                        setModifier={setModifier}
                        activeModifier={modifier}
                    />

                    {!props.readonly && (
                        <TriggerAddDropDown
                            title={title}
                            searchString={searchString}
                            setSearchString={setSearchString}
                            addTrigger={addTrigger}
                            activeModifier={props.modifier}
                            setActiveModifier={props.setModifier}
                            selectedTriggers={data}
                            userStatus={userStatus}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default CollapsibleSection;
