import { auth } from '../../base';
import { Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

export default function ProtectedRoute({ children }: any) {
    const [pending, setPending] = useState<boolean>(true);
    const [currentUser, setCurrentUser] = useState<User | null>();
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(
            auth,
            user => {
                setCurrentUser(user as User);
                setPending(false);
            },
            error => {
                setPending(false);
            }
        );

        return unsubscribe; // <-- clean up subscription
    }, []);

    if (pending) return null;
    return currentUser ? children : <Navigate replace to='/login' />;
}
