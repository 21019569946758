import { getStripePayments } from '@stripe/firestore-stripe-payments';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
    createUserWithEmailAndPassword,
    getAuth,
    UserCredential,
    sendPasswordResetEmail
} from 'firebase/auth';
import {
    doc,
    collection,
    getFirestore,
    getDoc,
    addDoc,
    onSnapshot,
    setDoc,
    getDocs
} from 'firebase/firestore';

import { httpsCallable, getFunctions } from 'firebase/functions';

import { IUser, LocalUser } from './models/firebase';
import { IStrategy } from './models/strategy';
import { message } from 'antd';

const firebaseConfig = {
    apiKey: 'AIzaSyBLLMJKTGwSegCHxB8hJC7skFJbCsqMy-M',
    authDomain: 'manta-8e645.firebaseapp.com',
    databaseURL: 'https://manta-8e645.firebaseio.com',
    projectId: 'manta-8e645',
    storageBucket: 'manta-8e645.appspot.com',
    messagingSenderId: '104012874990',
    appId: '1:104012874990:web:abb9d510a536e2d0f29fff',
    measurementId: 'G-W30G2HK164'
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = getAuth(app);
const functions = getFunctions(app);

let db = getFirestore(app);

let host = 'https://fulcheranalytics-api.herokuapp.com';

if (process.env.REACT_APP_HOST_ENV === 'local') {
    host = 'http://127.0.0.1:8000';
}

// const storage = app.storage();

export const fetchUser = async (): Promise<[boolean, IUser | null]> => {
    if (auth.currentUser) {
        // user
        const docRef = doc(db, 'users', auth.currentUser.uid);
        const docSnap = await getDoc(docRef);

        // tests
        const collectionRef = collection(db, `users/${auth.currentUser.uid}/tests`);
        const snapshot = await getDocs(collectionRef);
        let tests = snapshot.docs.map(doc => doc.data());

        // user claim role
        const role = await getCustomClaimRole();
        return [
            !role,
            { ...(docSnap.data() as any), ...auth.currentUser, role: role, tests: tests }
        ];
    } else {
        return [false, null];
    }
};

export const fetchBacktests = () => {
    if (auth.currentUser) {
        const docRef = collection(db, `users/${auth.currentUser.uid}/tests`);
        onSnapshot(docRef, querySnapshot => {
            const data = querySnapshot.docs.map(doc => doc.data());
            console.log(data);
            return data;
        });
    }
};

export const createOrUpdateStrategy = async (strategy: IStrategy, bot_id?: number) => {
    //get the current user
    if (auth.currentUser) {
        const docRef = doc(db, 'users', auth.currentUser.uid);
        let docSnap = await (await getDoc(docRef)).data();
        if (docSnap) {
            if (bot_id) {
                docSnap.strategies = docSnap.strategies.filter(
                    (strategy: IStrategy) => strategy.id !== bot_id
                );
            }

            docSnap.strategies.push(strategy);
            await setDoc(doc(db, 'users', auth.currentUser.uid), docSnap).catch(e =>
                message.error(`Failed to ${bot_id ? 'update' : 'create'} strategy`)
            );
        }
    }
};

export const deleteStrategy = async (strategyId: number) => {
    if (auth.currentUser) {
        const docRef = doc(db, 'users', auth.currentUser.uid);
        let docSnap = await (await getDoc(docRef)).data();
        if (docSnap) {
            docSnap.strategies = docSnap.strategies.filter((strategy: IStrategy) => {
                return strategy.id !== strategyId;
            });

            await setDoc(doc(db, 'users', auth.currentUser.uid), docSnap).catch(e =>
                message.error(`Failed to delete strategy`)
            );
        }
    }
};

export const doCreateUser = async (
    email: string,
    password: string,
    zipCode: number | null,
    firstName: string,
    lastName: string
) => {
    await createUserWithEmailAndPassword(auth, email, password).then((user: UserCredential) => {
        const userId = user.user.uid;
        setDoc(doc(db, 'users', userId), {
            firstName: firstName,
            lastName: lastName,
            strategies: [],
            secret: null,
            zipCode: zipCode ? zipCode : null
        } as LocalUser);
    });
};

export const doUpdateUser = async (user: IUser) => {
    if (auth.currentUser) {
        const userId = auth.currentUser.uid;
        await setDoc(doc(db, 'users', userId), user);
    }
};

//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;

// const payments = getStripePayments(app, {
//     productsCollection: 'products',
//     customersCollection: 'customers'
// });

export async function getCustomClaimRole() {
    if (auth.currentUser) {
        await auth.currentUser.getIdToken(true);
        const decodedToken = await auth.currentUser.getIdTokenResult();
        const role = decodedToken.claims.stripeRole;
        if (role) {
            return role;
        } else {
            return 'free';
            //doCreateCheckout();
        }
    } else {
        return null;
    }
}

export const doCreateCheckout = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
        const docRef = await addDoc(
            collection(db, `customers/${currentUser.uid}/checkout_sessions`),
            {
                price: 'price_1Lv48cJUSTupoaFBFLcBKj2Z',
                success_url: window.location.origin,
                cancel_url: window.location.origin
            }
        );

        onSnapshot(docRef, snap => {
            const { error, url } = snap.data() as any;
            if (error) {
                // Show an error to your customer and
                // inspect your Cloud Function logs in the Firebase console.
                alert(`An error occured: ${error.message}`);
            }
            if (url) {
                // We have a Stripe Checkout URL, let's redirect.
                window.location.assign(url);
            }
        });
    }
};

export const doLaunchCustomerPortal = async () => {
    const createCustomerPortalUrl = httpsCallable(
        functions,
        'ext-firestore-stripe-payments-createPortalLink'
    );
    const { data } = await createCustomerPortalUrl({
        returnUrl: window.location.origin,
        locale: 'auto' // Optional, defaults to "auto"
    });
    window.location.assign((data as any).url);
};

export const resetPasswordWithEmail = async (email: string) => {
    return await sendPasswordResetEmail(auth, email)
        .then(res => {
            return {
                response: true,
                msg: 'We have sent reset link to your email.'
            };
        })
        .catch(error => {
            return {
                response: false,
                msg:
                    error.code === 'auth/user-not-found'
                        ? 'User is not registered'
                        : 'Something went wrong.'
            };
        });
};

export { app, db, host, analytics, auth };
