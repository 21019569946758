import React from 'react';

export default function Embed() {
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                position: 'fixed',
                top: 0
            }}
        >
            <span>Fulcher Analytics Embed</span>;
        </div>
    );
}
