export enum TriggerCategory {
    Event = 'event',
    Momentum = 'momentum',
    CandlePattern = 'pattern',
    Overlap = 'overlap',
    Statistics = 'statistics',
    Trend = 'trend',
    Volatility = 'volatility'
}

export const TriggerCategoryTitles = {
    event: 'Event',
    momentum: 'Momentum',
    pattern: 'Candle Pattern',
    overlap: 'Overlap Studies',
    statistics: 'Statistics',
    trend: 'Trend',
    volatility: 'Volatility'
};

export enum TriggerDomain {
    Entry,
    Exit
}

export interface Param {
    type: 'string' | 'number' | 'select';
    title: string;
    id: string;
    value?: any;
    preFix?: string;
    postFix?: string;
    defaultValue?: any;
    options?: Array<{ value: string; label: string }>;
}

export interface Trigger {
    id: string;
    title: string;
    inlineTitle: string;
    category: TriggerCategory;
    modifier?: string;
    domain?: TriggerDomain;
    params?: Array<Param>;
}

export const triggers: Array<Trigger> = [
    {
        id: 'event_any',
        inlineTitle: 'true (always triggers)',
        title: 'Always Trigger',
        category: TriggerCategory.Event
    },
    {
        id: 'event_positionchange',
        title: 'Position Change %',
        inlineTitle: 'the position has {direction} by {percent}',
        domain: TriggerDomain.Exit,
        category: TriggerCategory.Event,
        params: [
            {
                id: 'direction',
                type: 'select',
                title: 'Increased/Decreased',
                options: [
                    { value: 'increased', label: 'Increased' },
                    { value: 'decreased', label: 'Decreased' }
                ]
            },
            { id: 'percent', type: 'string', postFix: '%', title: 'Percent Change' }
        ]
    },
    {
        id: 'event_positionchangedollar',
        title: 'Position Change $',
        inlineTitle: 'the position has {direction} by {dollars}',
        category: TriggerCategory.Event,
        domain: TriggerDomain.Exit,
        params: [
            {
                id: 'direction',
                type: 'select',
                title: 'Increased/Decreased',
                options: [
                    { value: 'increased', label: 'Increased' },
                    { value: 'decreased', label: 'Decreased' }
                ]
            },
            { id: 'dollars', type: 'string', preFix: '$', title: 'Dollar Change' }
        ]
    },
    {
        id: 'event_change',
        title: 'Share Price Change Over Number of Periods',
        category: TriggerCategory.Event,
        inlineTitle: 'has a share price {direction} over the past {periods} periods',
        params: [
            {
                id: 'direction',
                type: 'select',
                title: 'Increase/Decrease',
                options: [
                    { value: 'increase', label: 'Increase' },
                    { value: 'decrease', label: 'Decrease' }
                ]
            },
            { title: 'Periods', id: 'periods', type: 'number' }
        ]
    },
    {
        id: 'event_pricetarget',
        title: 'Price Target',
        inlineTitle: 'has a price {greaterless} than {pri}',
        category: TriggerCategory.Event,
        params: [
            { title: 'Price', id: 'pri', type: 'number', preFix: '$' },
            {
                id: 'greaterless',
                type: 'select',
                title: 'Greater/Less',
                options: [
                    { value: 'greater', label: 'Greater Than' },
                    { value: 'less', label: 'Less Than' }
                ]
            }
        ]
    },
    {
        id: 'event_supportresistance',
        title: 'Support / Resistance',
        inlineTitle: 'reaches {period}period {supres} ',
        category: TriggerCategory.Event,
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'supres',
                type: 'select',
                title: 'Support/Resistance',
                postFix: 'level',
                options: [
                    { value: 'support', label: 'Support' },
                    { value: 'resistance', label: 'Resistance' }
                ]
            }
        ]
    } /********************         CANDLE PATTERNS     ******************************/,
    {
        id: '2crows',
        title: 'Two Crows',
        inlineTitle: 'Two Crows pattern is recognized over the past {periods} periods',
        category: TriggerCategory.CandlePattern,
        params: [{ title: 'Period', id: 'periods', type: 'number' }]
    },
    {
        id: '3blackcrows',
        title: 'Three Black Crows',
        inlineTitle: 'Three Black Crows pattern is recognized over the past {periods} periods',
        category: TriggerCategory.CandlePattern,
        params: [{ title: 'Period', id: 'periods', type: 'number' }]
    },
    {
        id: '3inside',
        title: 'Three Inside',
        inlineTitle: 'Three Inside pattern is recognized over the past {periods} periods',
        category: TriggerCategory.CandlePattern,
        params: [{ title: 'Period', id: 'periods', type: 'number' }]
    },
    {
        id: '3linestrike',
        title: 'Three Line Strike',
        inlineTitle: 'Three Line Strike pattern is recognized over the past {periods} periods',
        category: TriggerCategory.CandlePattern,
        params: [{ title: 'Period', id: 'periods', type: 'number' }]
    },
    {
        id: '3outside',
        title: 'Three Outside',
        inlineTitle: 'Three Outside pattern is recognized over the past {periods} periods',
        category: TriggerCategory.CandlePattern,
        params: [{ title: 'Period', id: 'periods', type: 'number' }]
    },
    {
        id: '3starsinsouth',
        title: 'Three Stars in the South',
        inlineTitle: 'Three Stars in the South pattern recognized over the past {periods} periods',
        category: TriggerCategory.CandlePattern,
        params: [{ title: 'Period', id: 'periods', type: 'number' }]
    },
    {
        id: '3whitesoldiers',
        title: 'Three White Soliders',
        inlineTitle: 'Three White Soldiers pattern recognized over the past {periods} periods',
        category: TriggerCategory.CandlePattern,
        params: [{ title: 'Period', id: 'periods', type: 'number' }]
    },
    {
        id: 'advanceblock',
        title: 'Advance Block',
        inlineTitle: 'Advance Block pattern recognized over the past {periods} periods',
        category: TriggerCategory.CandlePattern,
        params: [{ title: 'Period', id: 'periods', type: 'number' }]
    },
    {
        id: 'belthold',
        title: 'Belt Hold',
        inlineTitle: 'Belt Hold pattern is recognized over the past {periods} periods',
        category: TriggerCategory.CandlePattern,
        params: [{ title: 'Period', id: 'periods', type: 'number' }]
    },
    // {
    //     id: 'breakaway',
    //     title: 'breakaway',
    //     inlineTitle: 'breakaway',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'closingmarubozu',
    //     title: 'closingmarubozu',
    //     inlineTitle: 'closingmarubozu',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'concealbabyswall',
    //     title: 'concealbabyswall',
    //     inlineTitle: 'concealbabyswall',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'counterattack',
    //     title: 'counterattack',
    //     inlineTitle: 'counterattack',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'darkcloudcover',
    //     title: 'darkcloudcover',
    //     inlineTitle: 'darkcloudcover',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: '**doji**',
    //     title: '**doji**',
    //     inlineTitle: '**doji**',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'dojistar',
    //     title: 'dojistar',
    //     inlineTitle: 'dojistar',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'dragonflydoji',
    //     title: 'dragonflydoji',
    //     inlineTitle: 'dragonflydoji',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'engulfing',
    //     title: 'engulfing',
    //     inlineTitle: 'engulfing',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'eveningdojistar',
    //     title: 'eveningdojistar',
    //     inlineTitle: 'eveningdojistar',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'eveningstar',
    //     title: 'eveningstar',
    //     inlineTitle: 'eveningstar',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'gapsidesidewhite',
    //     title: 'gapsidesidewhite',
    //     inlineTitle: 'gapsidesidewhite',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'gravestonedoji',
    //     title: 'gravestonedoji',
    //     inlineTitle: 'gravestonedoji',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'hammer',
    //     title: 'hammer',
    //     inlineTitle: 'hammer',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'hangingman',
    //     title: 'hangingman',
    //     inlineTitle: 'hangingman',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'harami',
    //     title: 'harami',
    //     inlineTitle: 'harami',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'haramicross',
    //     title: 'haramicross',
    //     inlineTitle: 'haramicross',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'highwave',
    //     title: 'highwave',
    //     inlineTitle: 'highwave',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'hikkake',
    //     title: 'hikkake',
    //     inlineTitle: 'hikkake',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'hikkakemod',
    //     title: 'hikkakemod',
    //     inlineTitle: 'hikkakemod',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'homingpigeon',
    //     title: 'homingpigeon',
    //     inlineTitle: 'homingpigeon',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'identical3crows',
    //     title: 'identical3crows',
    //     inlineTitle: 'identical3crows',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'inneck',
    //     title: 'inneck',
    //     inlineTitle: 'inneck',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: '**inside**',
    //     title: '**inside**',
    //     inlineTitle: '**inside**',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'invertedhammer',
    //     title: 'invertedhammer',
    //     inlineTitle: 'invertedhammer',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'kicking',
    //     title: 'kicking',
    //     inlineTitle: 'kicking',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'kickingbylength',
    //     title: 'kickingbylength',
    //     inlineTitle: 'kickingbylength',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'ladderbottom',
    //     title: 'ladderbottom',
    //     inlineTitle: 'ladderbottom',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'longleggeddoji',
    //     title: 'longleggeddoji',
    //     inlineTitle: 'longleggeddoji',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'longline',
    //     title: 'longline',
    //     inlineTitle: 'longline',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'marubozu',
    //     title: 'marubozu',
    //     inlineTitle: 'marubozu',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'matchinglow',
    //     title: 'matchinglow',
    //     inlineTitle: 'matchinglow',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'mathold',
    //     title: 'mathold',
    //     inlineTitle: 'mathold',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'morningdojistar',
    //     title: 'morningdojistar',
    //     inlineTitle: 'morningdojistar',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'morningstar',
    //     title: 'morningstar',
    //     inlineTitle: 'morningstar',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'onneck',
    //     title: 'onneck',
    //     inlineTitle: 'onneck',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'piercing',
    //     title: 'piercing',
    //     inlineTitle: 'piercing',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'rickshawman',
    //     title: 'rickshawman',
    //     inlineTitle: 'rickshawman',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'risefall3methods',
    //     title: 'risefall3methods',
    //     inlineTitle: 'risefall3methods',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'separatinglines',
    //     title: 'separatinglines',
    //     inlineTitle: 'separatinglines',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'shootingstar',
    //     title: 'shootingstar',
    //     inlineTitle: 'shootingstar',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'shortline',
    //     title: 'shortline',
    //     inlineTitle: 'shortline',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'spinningtop',
    //     title: 'spinningtop',
    //     inlineTitle: 'spinningtop',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'stalledpattern',
    //     title: 'stalledpattern',
    //     inlineTitle: 'stalledpattern',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'sticksandwich',
    //     title: 'sticksandwich',
    //     inlineTitle: 'sticksandwich',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'takuri',
    //     title: 'takuri',
    //     inlineTitle: 'takuri',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'tasukigap',
    //     title: 'tasukigap',
    //     inlineTitle: 'tasukigap',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'thrusting',
    //     title: 'thrusting',
    //     inlineTitle: 'thrusting',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'tristar',
    //     title: 'tristar',
    //     inlineTitle: 'tristar',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'unique3river',
    //     title: 'unique3river',
    //     inlineTitle: 'unique3river',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'upsidegap2crows',
    //     title: 'upsidegap2crows',
    //     inlineTitle: 'upsidegap2crows',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'periods', type: 'number' }]
    // },
    // {
    //     id: 'xsidegap3methods',
    //     title: 'xsidegap3methods',
    //     inlineTitle: 'xsidegap3methods',
    //     category: TriggerCategory.CandlePattern,
    //     params: [{ title: 'Period', id: 'perio]ds', type: 'number' }]
    // } /********************         OVERLAP STUDIES ******************************/,
    {
        id: 'alma',
        title: 'Arnaud Legoux Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Arnaud Legoux Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'dema',
        title: 'Double Exponential Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Double Exponential Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'ema',
        title: 'Exponential Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Exponential Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'fwma',
        title: 'Fibonaccis Weighted Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Fibonaccis Weighted Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'hilo',
        title: 'Gann High-Low Activator',
        inlineTitle: 'price crosses {overunder} {period} period Gann High-Low Activator',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'hl2',
        title: 'High-Low Average',
        inlineTitle: 'price crosses {overunder} {period} period High-Low Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'hlc3',
        title: 'High-Low-Close Average',
        inlineTitle: 'price crosses {overunder} {period} period High-Low-Close Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'hma',
        title: 'Hull Exponential Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Hull Exponential Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'hwma',
        title: 'Holt-Winter Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Holt-Winter Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'ichimoku',
        title: 'Ichimoku Kinkō Hyō',
        inlineTitle: 'price crosses {overunder} {period} period Ichimoku Kinkō Hyō',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'jma',
        title: 'Jurik Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Jurik Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'kama',
        title: 'Kaufmans Adaptive Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Kaufmans Adaptive Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'linreg',
        title: 'Linear Regression',
        inlineTitle: 'price crosses {overunder} {period} period Linear Regression',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'mcgd',
        title: 'McGinley Dynamic',
        inlineTitle: 'price crosses {overunder} {period} period McGinley Dynamic',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'midpoint',
        title: 'Midpoint',
        inlineTitle: 'price crosses {overunder} {period} period Midpoint',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'midprice',
        title: 'Midprice',
        inlineTitle: 'price crosses {overunder} {period} period Midprice',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'ohlc4',
        title: 'Open-High-Low-Close Average',
        inlineTitle: 'price crosses {overunder} {period} period Open-High-Low-Close Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'pwma',
        title: 'Pascals Weighted Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Pascals Weighted Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'rma',
        title: 'WildeRs Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period WildeRs Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'sinwma',
        title: 'Sine Weighted Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Sine Weighted Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'sma',
        title: 'Simple Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Simple Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'ssf',
        title: 'Ehlers Super Smoother Filter',
        inlineTitle: 'price crosses {overunder} {period} period Ehlers Super Smoother Filter',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'supertrend',
        title: 'Supertrend',
        inlineTitle: 'price crosses {overunder} {period} period Supertrend',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'swma',
        title: 'Symmetric Weighted Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Symmetric Weighted Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 't3',
        title: 'T3 Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period T3 Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'tema',
        title: 'Triple Exponential Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Triple Exponential Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'trima',
        title: 'Triangular Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Triangular Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'vidya',
        title: 'Variable Index Dynamic Average',
        inlineTitle: 'price crosses {overunder} {period} period Variable Index Dynamic Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'vwap',
        title: 'Volume Weighted Average Price',
        inlineTitle: 'price crosses {overunder} {period} period Volume Weighted Average Price',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'vwma',
        title: 'Volume Weighted Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Volume Weighted Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'wcp',
        title: 'Weighted Closing Price',
        inlineTitle: 'price crosses {overunder} {period} period Weighted Closing Price',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'wma',
        title: 'Weighted Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Weighted Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    {
        id: 'zlma',
        title: 'Zero Lag Moving Average',
        inlineTitle: 'price crosses {overunder} {period} period Zero Lag Moving Average',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            {
                id: 'overunder',
                type: 'select',
                title: 'Over/Under',
                options: [{ value: 'over', label: 'Over' }, { value: 'under', label: 'Under' }]
            }
        ],
        category: TriggerCategory.Overlap
    },
    /*STATISTICS*/
    {
        id: 'entropy',
        title: 'Entropy',
        inlineTitle: '{periods} period Entropy is {greaterless} than {value}',
        params: [
            { title: 'Number', id: 'value', type: 'number' },
            { title: 'Periods', id: 'periods', type: 'number' },
            {
                id: 'greaterless',
                type: 'select',
                title: 'Greater/Less',
                options: [
                    { value: 'greater', label: 'Greater Than' },
                    { value: 'less', label: 'Less Than' }
                ]
            }
        ],
        category: TriggerCategory.Statistics
    },
    {
        id: 'kurtosis',
        title: 'Kurtosis',
        inlineTitle: '{periods} period Kurtosis is {greaterless} than {value}',
        params: [
            { title: 'Number', id: 'value', type: 'number' },
            { title: 'Periods', id: 'periods', type: 'number' },
            {
                id: 'greaterless',
                type: 'select',
                title: 'Greater/Less',
                options: [
                    { value: 'greater', label: 'Greater Than' },
                    { value: 'less', label: 'Less Than' }
                ]
            }
        ],
        category: TriggerCategory.Statistics
    },
    {
        id: 'mad',
        title: 'MeanAbsoluteDeviation',
        inlineTitle: '{periods} period MeanAbsoluteDeviation is {greaterless} than {value}',
        params: [
            { title: 'Number', id: 'value', type: 'number' },
            { title: 'Periods', id: 'periods', type: 'number' },
            {
                id: 'greaterless',
                type: 'select',
                title: 'Greater/Less',
                options: [
                    { value: 'greater', label: 'Greater Than' },
                    { value: 'less', label: 'Less Than' }
                ]
            }
        ],
        category: TriggerCategory.Statistics
    },
    {
        id: 'median',
        title: 'Median',
        inlineTitle: '{periods} period Median is {greaterless} than {value}',
        params: [
            { title: 'Number', id: 'value', type: 'number' },
            { title: 'Periods', id: 'periods', type: 'number' },
            {
                id: 'greaterless',
                type: 'select',
                title: 'Greater/Less',
                options: [
                    { value: 'greater', label: 'Greater Than' },
                    { value: 'less', label: 'Less Than' }
                ]
            }
        ],
        category: TriggerCategory.Statistics
    },
    {
        id: 'quantile',
        title: 'Quantile',
        inlineTitle: '{periods} period Quantile is {greaterless} than {value}',
        params: [
            { title: 'Number', id: 'value', type: 'number' },
            { title: 'Periods', id: 'periods', type: 'number' },
            {
                id: 'greaterless',
                type: 'select',
                title: 'Greater/Less',
                options: [
                    { value: 'greater', label: 'Greater Than' },
                    { value: 'less', label: 'Less Than' }
                ]
            }
        ],
        category: TriggerCategory.Statistics
    },
    {
        id: 'skew',
        title: 'Skew',
        inlineTitle: '{periods} period Skew is {greaterless} than {value}',
        params: [
            { title: 'Number', id: 'value', type: 'number' },
            { title: 'Periods', id: 'periods', type: 'number' },
            {
                id: 'greaterless',
                type: 'select',
                title: 'Greater/Less',
                options: [
                    { value: 'greater', label: 'Greater Than' },
                    { value: 'less', label: 'Less Than' }
                ]
            }
        ],
        category: TriggerCategory.Statistics
    },
    {
        id: 'stdev',
        title: 'StandardDeviation',
        inlineTitle: '{periods} period Standard Deviation is {greaterless} than {value}',
        params: [
            { title: 'Number', id: 'value', type: 'number' },
            { title: 'Periods', id: 'periods', type: 'number' },
            {
                id: 'greaterless',
                type: 'select',
                title: 'Greater/Less',
                options: [
                    { value: 'greater', label: 'Greater Than' },
                    { value: 'less', label: 'Less Than' }
                ]
            }
        ],
        category: TriggerCategory.Statistics
    },
    {
        id: 'variance',
        title: 'Variance',
        inlineTitle: '{periods} period Variance is {greaterless} than {value}',
        params: [
            { title: 'Number', id: 'value', type: 'number' },
            { title: 'Periods', id: 'periods', type: 'number' },
            {
                id: 'greaterless',
                type: 'select',
                title: 'Greater/Less',
                options: [
                    { value: 'greater', label: 'Greater Than' },
                    { value: 'less', label: 'Less Than' }
                ]
            }
        ],
        category: TriggerCategory.Statistics
    },
    {
        id: 'zscore',
        title: 'ZScore',
        inlineTitle: '{periods} period ZScore is {greaterless} than {value}',
        params: [
            { title: 'Number', id: 'value', type: 'number' },
            { title: 'Periods', id: 'periods', type: 'number' },
            {
                id: 'greaterless',
                type: 'select',
                title: 'Greater/Less',
                options: [
                    { value: 'greater', label: 'Greater Than' },
                    { value: 'less', label: 'Less Than' }
                ]
            }
        ],
        category: TriggerCategory.Statistics
    },
    // {
    //     id: 'amat',
    //     title: 'adx',
    //     inlineTitle: 'adx',
    //     category: TriggerCategory.Trend
    // }
    // {
    //     id: 'adx',
    //     title: 'Average Directional Movement Index',
    //     inlineTitle: 'Price is {abovebelow} {period} period ADX',
    //     params: [
    //         { title: 'Period', id: 'period', type: 'number' },
    //         {
    //             id: 'abovebelow',
    //             type: 'select',
    //             title: 'Above/Below',
    //             options: [{ value: 'above', label: 'Above' }, { value: 'below', label: 'Below' }]
    //         }
    //     ],
    //     category: TriggerCategory.Momentum
    // },
    {
        id: 'apo',
        title: 'Absolute Price Oscillator',
        inlineTitle: 'Absolute Price Oscillator is {abovebelow}  {number} ',
        params: [
            { title: 'Number', id: 'number', type: 'number' },
            {
                id: 'abovebelow',
                type: 'select',
                title: 'Above/Below',
                options: [{ value: 'above', label: 'Above' }, { value: 'below', label: 'Below' }]
            }
        ],
        category: TriggerCategory.Momentum
    },
    {
        id: 'rsi',
        title: 'Relative Strength Index',
        inlineTitle: '{period} period RSI is {abovebelow} {number}',
        params: [
            { title: 'Period', id: 'period', type: 'number' },
            { title: 'Number', id: 'number', type: 'number' },
            {
                id: 'abovebelow',
                type: 'select',
                title: 'Above/Below',
                options: [{ value: 'above', label: 'Above' }, { value: 'below', label: 'Below' }]
            }
        ],
        category: TriggerCategory.Momentum
    }
    // {
    //     id: 'bbands',
    //     title: 'Bollinger Bands',
    //     inlineTitle: 'Price {crossnear} the {band} Bollinger Band',
    //     params: [
    //         {
    //             id: 'band',
    //             type: 'select',
    //             title: 'Band',
    //             options: [
    //                 { value: 'upper', label: 'Upper' },
    //                 { value: 'middle', label: 'Middle' },
    //                 { value: 'lower', label: 'Lower' }
    //             ]
    //         },
    //         {
    //             id: 'crossnear',
    //             type: 'select',
    //             title: 'Crosses or Nears',
    //             options: [
    //                 { value: 'crossbelow', label: 'Crosses Above' },
    //                 { value: 'crossabove', label: 'Crosses Below' },
    //                 { value: 'nears', label: 'Nears' }
    //             ]
    //         }
    //     ],
    //     category: TriggerCategory.Volatility
    // }
];
