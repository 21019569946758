import React, { useEffect } from 'react';
import { IBacktest, StandAloneChart } from '../../../models/api';
import {
    Line,
    Legend,
    CartesianGrid,
    LineChart,
    ResponsiveContainer,
    XAxis,
    YAxis,
    Tooltip,
    AreaChart,
    Area,
    Brush,
    BarChart,
    Bar,
    ComposedChart,
    Scatter
} from 'recharts';
import moment from 'moment';
import { Card, Col, List, Row, Collapse } from 'antd';
import Statistics from './Statistics';
import { triggers } from '../StrategyBuilder/triggers';

const { Panel } = Collapse;

interface BackTestResultsProps {
    data: IBacktest;
}

const colors = ['#69A297', '#754668', '#35524A', '#FF0022'];

export default function BackTestResults({ data }: BackTestResultsProps) {
    const indicatorNameMap: any = triggers.reduce((obj, item) => {
        return {
            ...obj,
            [item['id']]: item['title']
        };
    });

    const createDynamicChart = (item: StandAloneChart) => {
        switch (item.type) {
            case 'line':
                return (
                    <LineChart width={500} height={300} data={item.chart} syncId='master'>
                        {/* <CartesianGrid strokeDasharray='3 3' /> */}
                        <XAxis
                            dataKey='date'
                            tickFormatter={unixTime => moment(unixTime).format('MM/DD/YY')}
                        />
                        <YAxis domain={['dataMin - 5', 'auto']} />
                        <Legend formatter={value => indicatorNameMap[value]} />
                        <Tooltip
                            labelFormatter={unixTime =>
                                'Date: ' + moment(unixTime).format('MM/DD/YY')
                            }
                            formatter={(value, name) => [
                                `${(value as number).toFixed(2)}`,
                                indicatorNameMap[name]
                            ]}
                        />
                        {/* <Line type='monotone' dataKey='close' stroke='#8884d8' activeDot={{ r: 8 }} /> */}
                        <Line type='monotone' dataKey={item.dataKey} stroke='#1990ff' dot={<></>} />
                    </LineChart>
                );
            case 'bar':
                return (
                    <BarChart
                        width={500}
                        height={300}
                        syncId='master'
                        data={item.chart}
                        margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5
                        }}
                    >
                        {/* <CartesianGrid strokeDasharray="3 3" /> */}
                        <XAxis dataKey='name' />
                        <YAxis />
                        <Tooltip
                            labelFormatter={unixTime =>
                                'Date: ' + moment(unixTime).format('MM/DD/YY')
                            }
                            formatter={(value, name) => [
                                `${(value as number).toFixed(2)}`,
                                indicatorNameMap[name]
                            ]}
                        />
                        {/* <Legend verticalAlign='top' wrapperStyle={{ lineHeight: '40px' }} /> */}
                        {/* <ReferenceLine y={0} stroke='#000' /> */}
                        {/* <Brush dataKey="name" height={30} stroke="#8884d8" /> */}
                        <Bar dataKey={item.dataKey} fill='#1990ff' />
                    </BarChart>
                );
            case 'area':
                return (
                    <AreaChart
                        width={600}
                        syncId='master'
                        height={100}
                        data={item.chart}
                        title={item.title}
                        margin={{
                            top: 10,
                            right: 30,
                            left: 0,
                            bottom: 0
                        }}
                    >
                        <CartesianGrid strokeDasharray='3 3' />
                        <XAxis dataKey='date' />
                        <YAxis />
                        <Tooltip />
                        <Area
                            type='monotone'
                            dataKey={item.dataKey}
                            stroke='#1990ff'
                            fill='#1990ff'
                        />
                    </AreaChart>
                );
        }
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <Row gutter={4}>
                <Col span={12} style={{ padding: 15 }}>
                    <Card style={{ height: 350 }}>
                        <h4>Portfolio Value</h4>
                        <ResponsiveContainer width='100%' height={280}>
                            <ComposedChart data={data.portfolio.chart} syncId='master'>
                                <XAxis
                                    dataKey='date'
                                    tickFormatter={unixTime => moment(unixTime).format('MM/DD/YY')}
                                />
                                <YAxis
                                    domain={['dataMin - 5', 'auto']}
                                    yAxisId='left'
                                    // unit
                                />
                                <Tooltip
                                    labelFormatter={unixTime =>
                                        'Date: ' + moment(unixTime).format('MM/DD/YY')
                                    }
                                    formatter={(value, name) => [
                                        `$${parseFloat(
                                            (value as number).toFixed(2)
                                        ).toLocaleString()}`,
                                        'Portfolio Value'
                                    ]}
                                />
                                <Area
                                    type='monotone'
                                    yAxisId={'left'}
                                    dataKey={'portfolio_value'}
                                    stroke='#1990ff'
                                    strokeWidth={2}
                                    fill='#1990ff'
                                />
                                {/* <Bar dataKey={'change'} fill='#1990ff' yAxisId={'right'} /> */}
                            </ComposedChart>
                        </ResponsiveContainer>
                    </Card>
                </Col>

                <Col span={12} style={{ padding: 15 }}>
                    <Card style={{ height: 350 }}>
                        <h4>Statistics</h4>
                        <Statistics data={data} />
                    </Card>
                </Col>
            </Row>
            <div style={{ padding: 15 }}>
                <Card>
                    <h4>Trade Recap</h4>
                    <ResponsiveContainer width='100%' height={400}>
                        <ComposedChart
                            width={600}
                            height={300}
                            data={data.timeseries}
                            syncId='master'
                        >
                            <CartesianGrid strokeDasharray='3 3' />
                            {/* <Brush /> */}
                            <Legend />
                            <XAxis
                                dataKey='date'
                                tickFormatter={unixTime => moment(unixTime).format('MM/DD/YY')}
                            />
                            <YAxis domain={['dataMin - 10', 'auto']} />
                            <Tooltip
                                labelFormatter={unixTime =>
                                    'Date: ' + moment(unixTime).format('MM/DD/YY')
                                }
                                formatter={(value, name) => {
                                    if (name === 'close') {
                                        return [`$${(value as number).toFixed(2)}`, 'Close Price'];
                                    } else if (name === 'buy') {
                                        return [`$${value}`, 'Buy'];
                                    } else if (name === 'sell') {
                                        return [`$${value}`, 'Sell'];
                                    } else if (
                                        Object.keys(indicatorNameMap).includes(name as string)
                                    ) {
                                        return [
                                            `$${(value as number).toFixed(2)}`,
                                            indicatorNameMap[name as any]
                                        ];
                                    }

                                    return [`$${(value as number).toFixed(2)}`, name];
                                }}
                            />
                            {/* <Line type='monotone' dataKey='close' stroke='#8884d8' activeDot={{ r: 8 }} /> */}
                            <Line
                                type='monotone'
                                strokeWidth={2}
                                dataKey='close'
                                stroke='#1990ff'
                                dot={false}
                            />
                            {data.timeseries_datum.map((value: string, index: number) => (
                                <Line
                                    type='monotone'
                                    strokeWidth={2}
                                    dataKey={value}
                                    stroke={
                                        index < colors.length
                                            ? colors[index]
                                            : colors[Math.floor(Math.random() * colors.length)]
                                    }
                                    dot={false}
                                />
                            ))}
                            <Scatter dataKey='buy' fill='green' />
                            <Scatter dataKey='sell' fill='red' />
                        </ComposedChart>
                    </ResponsiveContainer>
                    <ResponsiveContainer width='100%' height={225}>
                        <ComposedChart data={data.portfolio.chart} syncId='master' height={200}>
                            <XAxis
                                dataKey='date'
                                tickFormatter={(value, index) => ''}
                                tickCount={0}
                                tickLine={false}
                                stroke={'white'}
                                height={10}
                            />
                            <YAxis unit='%' />
                            <Tooltip
                                labelFormatter={unixTime =>
                                    'Date: ' + moment(unixTime).format('MM/DD/YY')
                                }
                                formatter={(value, name) => [`${value}%`, 'Portfolio Change']}
                            />
                            <Bar dataKey={'change'} fill='#1990ff' />
                            <Brush height={30} />
                        </ComposedChart>
                    </ResponsiveContainer>
                </Card>
            </div>
            <Card style={{ marginTop: 10 }}>
                {data.standalone.map(item => (
                    <>
                        <ResponsiveContainer width='100%' height={120}>
                            {createDynamicChart(item)}
                        </ResponsiveContainer>
                    </>
                ))}
            </Card>
        </div>
    );
}
