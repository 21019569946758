import React from 'react';
import logo from '../images/favicon.png';
import { Row, Col } from 'antd';

export default class Footer extends React.Component {
    render() {
        return (
            <Row
                style={{
                    position: 'fixed',
                    bottom: 0,
                    height: 50,
                    borderTop: '0.5px solid #F0F3F6',
                    width: '100%',
                    paddingTop: 6,
                    paddingRight: 30,
                    background: 'white'
                }}
            >
                <Col style={{ marginRight: 10 }}>
                    <img src={logo} height={40} />
                </Col>
                <Col style={{ paddingTop: 10, fontWeight: 300, fontSize: 12 }}>
                    <span>© FULCHER ANALYTICS {new Date().getFullYear()}</span>
                </Col>
                {/* <Col
                    span={2}
                    style={{ paddingTop: 10, fontWeight: 300, fontSize: 12, cursor: 'pointer' }}
                >
                    <span>Support</span>
                </Col>
                <Col
                    span={2}
                    style={{ paddingTop: 10, fontWeight: 300, fontSize: 12, cursor: 'pointer' }}
                >
                    <span>Pricing</span>
                </Col>
                <Col
                    span={2}
                    style={{ paddingTop: 10, fontWeight: 300, fontSize: 12, cursor: 'pointer' }}
                >
                    <span>About</span>
                </Col> */}
            </Row>
        );
    }
}
