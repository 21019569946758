import React, { useState } from 'react';
import { IUser } from '../../../models/firebase';
import { message, Tag, Tooltip, Tabs, Card, Form, Input, Button } from 'antd';
import { auth, doLaunchCustomerPortal, doUpdateUser } from '../../../base';
import { sendEmailVerification } from 'firebase/auth';
import { LoadingOutlined } from '@ant-design/icons';

import { PageHeader } from '@ant-design/pro-layout';


const { TabPane } = Tabs;

interface AccountProps {
    userData: IUser;
    onChange: (key: number) => void;
}

const Account = (props: AccountProps) => {
    const { userData, onChange } = props;

    const [user, setUser] = useState<IUser>(userData);
    const [planLoading, setPlanLoading] = useState<boolean>(false);

    return (
        <>
            <PageHeader className='site-page-header' onBack={() => onChange(0)} title='Account' />
            <Tabs>
                <TabPane tab='Account Settings' key='1'>
                    <Card>
                        <h2>Settings</h2>

                        {userData && (
                            <Form onFinish={values => { }}>
                                <Form.Item label={'First Name'}>
                                    <Input
                                        value={user.firstName}
                                        style={{ width: 300 }}
                                        onChange={e => {
                                            setUser({ ...user, firstName: e.target.value });
                                        }}
                                    />
                                </Form.Item>
                                <Form.Item label={'Last Name'}>
                                    <Input
                                        style={{ width: 300 }}
                                        value={user.lastName}
                                        onChange={e => {
                                            setUser({ ...user, lastName: e.target.value });
                                        }}
                                    />
                                </Form.Item>
                                <Tooltip title='Emails cannot be changed, please contact support if it absolutely necessary'>
                                    <Form.Item label={'Email'} style={{ width: 400 }}>
                                        <Input
                                            style={{ width: 300 }}
                                            // value={this.state.userAccount.email}
                                            value={user.email ?? ""}
                                            onChange={e => null}
                                            disabled={true}
                                        />
                                    </Form.Item>{' '}
                                </Tooltip>

                                <Form.Item label={'Email Verification'}>
                                    {auth.currentUser && auth.currentUser.emailVerified ? (
                                        <Tag color='green'>Verified</Tag>
                                    ) : (
                                        <Tag
                                            color='red'
                                            onClick={() => {
                                                auth.currentUser &&
                                                    sendEmailVerification(auth.currentUser).then(
                                                        () => {
                                                            message.info('Email verification sent');
                                                        }
                                                    );
                                            }}
                                        >
                                            Unverified
                                        </Tag>
                                    )}
                                </Form.Item>
                                <Button type="primary" onClick={() => {
                                    doUpdateUser(user).then(() => {
                                        message.success("User updated")
                                    });
                                }}>
                                    Save
                                </Button>
                            </Form>
                        )}
                    </Card>
                </TabPane>
                <TabPane tab='Payment and Plan' key='3'>
                    {/* <PaymentPlan userData={this.props.userData} /> */}
                    <Button type="primary" onClick={() => {
                        setPlanLoading(true);
                        doLaunchCustomerPortal();

                    }}>Manage Plan {planLoading && <LoadingOutlined />}</Button>
                </TabPane>
            </Tabs>
        </>
    );
};

export default Account;
