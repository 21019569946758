import { Menu, Row, Col } from 'antd';
import {
    HourglassOutlined,
    LogoutOutlined,
    BarChartOutlined,
    BuildOutlined,
    SnippetsOutlined,
    UpCircleFilled,
    LoadingOutlined
} from '@ant-design/icons';
import React from 'react';
import logo from '../../images/favicon.png';
import { auth, doCreateCheckout } from '../../base';
import { IUser, LocalUser } from '../../models/firebase';

interface DashMenuProps {
    collapsed: boolean;
    currentKey: number;
    onChange: (key: number) => void;
    height: number;
    name: string;
    user: IUser;
}

class DashMenu extends React.Component<DashMenuProps> {
    // submenu keys of first level
    rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];

    constructor(props: DashMenuProps) {
        super(props);
        // this.state = {
        // };
        window.addEventListener('resize', this.update);
    }

    state = {
        key: 0,
        height: 0,
        collapsed: false,
        checkoutLoading: false
    };

    componentDidMount() {
        let newState: any = this.state;
        newState.height = document.body.scrollHeight;
        this.setState(newState);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.update);
    }

    update = () => {
        let newState: any = this.state;
        newState.height = document.body.scrollHeight;
        this.setState(newState);
    };

    checkEligibility = () => {
        this.props.onChange(2);
    };

    handleKeyChange = (event: any) => {
        this.setState({ key: event.key });
        this.props.onChange(parseInt(event.key));
    };

    render() {
        return (
            <Menu
                style={{ height: window.innerHeight - 48 }}
                defaultSelectedKeys={['0']}
                theme='light'
                onSelect={this.handleKeyChange}
                mode='inline'
                selectedKeys={[this.props.currentKey.toString()]}
            >
                <div
                    className='custom-menu-item'
                    style={{
                        paddingLeft: this.props.collapsed ? 0 : 20,
                        paddingTop: 17,
                        paddingBottom: 15
                    }}
                >
                    {!this.props.collapsed ? (
                        <Row className='masterLogo' style={{ fontSize: 24 }}>
                            <Col>
                                <img
                                    src={logo}
                                    alt='Manta'
                                    style={{
                                        height: 40,
                                        width: 40,
                                        // marginTop: -2,
                                        marginRight: 4,
                                        marginLeft: this.props.collapsed ? 18 : 0
                                    }}
                                />
                            </Col>
                            <Col style={{ paddingTop: 20 }}>
                                <span style={{ fontSize: 18 }}>Fulcher Analytics</span>
                            </Col>
                        </Row>
                    ) : (
                        <img
                            src={logo}
                            alt='Manta'
                            style={{
                                height: 50,
                                width: 50,
                                marginRight: 7,
                                marginLeft: this.props.collapsed ? 18 : 0
                            }}
                        />
                    )}
                </div>
                <Menu.Item key='0' icon={<BarChartOutlined />}>
                    Strategies{' '}
                </Menu.Item>
                {/* <Menu.Item key='1' icon={<BuildOutlined />}>
                    Strategy Builder
                </Menu.Item> */}
                <Menu.Item
                    key='4'
                    onClick={() => {
                        this.props.onChange(4);
                    }}
                    icon={<HourglassOutlined />}
                >
                    Back Test
                </Menu.Item>
                <Menu.Item
                    key='6'
                    onClick={() => {
                        this.props.onChange(6);
                    }}
                    icon={<SnippetsOutlined />}
                >
                    Feedback
                </Menu.Item>
                <Menu.Item
                    key='5'
                    onClick={() => {
                        auth.signOut();
                    }}
                    icon={<LogoutOutlined />}
                >
                    Signout
                </Menu.Item>
                {/* {this.props.user.role !== 'premium' && (
                    <>
                        <div style={{ padding: 10 }}>
                            <div style={{ borderBottom: '1px solid #f0f0f0' }} />
                        </div>
                        <Menu.Item
                            icon={
                                this.state.checkoutLoading ? (
                                    <LoadingOutlined color='#1990ff' />
                                ) : (
                                    <UpCircleFilled color='#1990ff' />
                                )
                            }
                            style={{ color: '#1990ff' }}
                        >
                            Trial Premium Features
                        </Menu.Item>
                    </>
                )} */}
                {this.props.user.role !== 'premium' && (
                    <>
                        <div style={{ padding: 10 }}>
                            <div style={{ borderBottom: '1px solid #f0f0f0' }} />
                        </div>
                        <li
                            className='ant-menu-item'
                            style={{
                                padding: this.props.collapsed ? '0px 31.5px' : '0px 16px 0px 24px',
                                color: '#1990ff'
                            }}
                            onClick={() => {
                                this.setState({ checkoutLoading: true });
                                doCreateCheckout();
                            }}
                        >
                            <span
                                className='anticon anticon-snippets ant-menu-item-icon'
                                style={{ color: '#1990ff !important' }}
                            >
                                {this.state.checkoutLoading ? (
                                    <LoadingOutlined color='#1990ff' />
                                ) : (
                                    <UpCircleFilled color='#1990ff' />
                                )}
                            </span>
                            <span
                                className='ant-menu-title-content'
                                style={{ color: '#1990ff !important' }}
                            >
                                Trial Premium Features
                            </span>
                        </li>
                    </>
                )}
            </Menu>
        );
    }
}

export default DashMenu;
