import React from 'react';
import Login from './pages/Login';
import ProtectedRoute from './components/Routes/PrivateRoute';
// import Dashboard from './pages/Dasboard';
import Dashboard from './pages/Dashboard';
import { AuthProvider } from './Auth';
import { Routes, Route } from 'react-router-dom';

import './style/App.less';
// import 'antd/dist/antd.less';
import Register from './pages/Register';
import ForgotPassword from './pages/ForgotPassword';
import { HubspotProvider } from '@aaronhayes/react-use-hubspot-form';
import Embed from './pages/Embed';

const App = () => {
    return (
        <div style={{ flex: '1' }}>
            <HubspotProvider>
                <AuthProvider>
                    <Routes>
                        <Route path='/login' element={<Login />} />
                        <Route path='/register' element={<Register />} />
                        <Route path='/forgot-password' element={<ForgotPassword />} />
                        <Route path='/embed' element={<Embed />} />
                        <Route
                            path='/'
                            element={
                                <ProtectedRoute>
                                    <Dashboard />
                                </ProtectedRoute>
                            }
                        />
                    </Routes>
                </AuthProvider>
            </HubspotProvider>
        </div>
    );
};

export default App;
