import React from 'react';
import axios from 'axios';
import { Card, Form, Button, message, Tag, Empty } from 'antd';
import { EditOutlined, LoadingOutlined } from '@ant-design/icons';
import '../../../../node_modules/react-linechart/dist/styles.css';
import { host, auth } from '../../../base';
import { Table, Drawer } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
    UpOutlined,
    DownOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    ReloadOutlined
} from '@ant-design/icons';
import BackTesterForm from './BackTesterForm';
import QuickEdit from '../StrategyBuilder/QuickEdit';
import { IStrategy } from '../../../models/strategy';
import BackTestResults from './BackTestResults';
import { AssetClass } from '../../../models/api';

const columns = [
    {
        title: 'Trade Entry Date',
        dataIndex: 'open',
        key: 'OpenData'
    },
    {
        title: 'Trade Exit Date',
        dataIndex: 'Date',
        key: 'Date'
    },
    {
        title: 'Position Size',
        dataIndex: 'Shares',
        key: 'Shares'
    },
    {
        title: 'Security',
        dataIndex: 'Security',
        key: 'Security'
    },
    {
        title: 'Entry Price',
        dataIndex: 'open_price',
        key: 'open_price',
        render: (float: number) => <span>${float.toFixed(2)}</span>
    },
    {
        title: 'Exit Price',
        dataIndex: 'Execution',
        key: 'execution',
        render: (float: number) => <span>${float.toFixed(2)}</span>
    },
    {
        title: 'Return',
        dataIndex: 'PNL',
        key: 'PNL',
        render: (data: any) => (
            <>
                {data === '-' ? (
                    <span>-</span>
                ) : (
                    <>
                        {data > 0 ? (
                            <span style={{ color: '#79d24c' }}>
                                <UpOutlined />
                                {(data * 100).toFixed(2)}%
                            </span>
                        ) : (
                            <span style={{ color: '#ff4d4f' }}>
                                <DownOutlined />
                                {(data * 100).toFixed(2)}%
                            </span>
                        )}
                    </>
                )}
            </>
        )
    }
];

export default class BackTester extends React.Component<any> {
    constructor(props: any) {
        super(props);
        this.state = {
            bots: [],
            botnames: [],
            quickEditOpen: false,
            stepResult: [],
            pricipal: 1000,
            running: false,
            result: '',
            complete: false,
            visible: false,
            running_data: [],
            showResults: false,
            calendar: [],
            data: {
                plot: [
                    {
                        data: [[0, 0]]
                    }
                ]
            },
            wins: 0,
            losses: 0,
            winrate: 0,
            trades: [],
            percentage: 0,
            buys: [],
            form: {},
            asset: { frequency: null, assetClass: AssetClass.EQUITY }
        };
    }

    checkMinuteEligibility = (bot: IStrategy) => {
        // return bot['selection']['frequency'].includes('m');
        // return bot.marketSelection.frequency.includes('m');
        return true;
    };

    reset = () => {
        let newState: any = this.getInitialState();
        newState.running = false;
        newState.error = false;
        newState.trades = [];
        newState.showResults = false;
        this.setState(newState);
        this.resetAll();
    };

    getChartData = (data: any, template = '') => {
        let series = [];
        try {
            for (const [key, value] of Object.entries(data)) {
                series.push({ data: value, name: key });
            }
            return series;
        } catch (err) {
            console.error('ERROR BELOW: ', err);
            message.error('There was an error');
        }
    };

    createPerformanceCalendar = () => {
        console.log(this.state);
        let trades: any = [];
        (this.state as any).data.trades.forEach((trade: any) => {
            if (Object.keys(trade).includes('sell')) {
                trades.push([
                    new Date(trade['sell']['date']).getTime(),
                    parseFloat((trade['sell']['PNL'] * 100).toFixed(2))
                ]);
            }
        });

        return [{ data: trades, name: 'Daily Returns' }];
    };

    getInitialState() {
        const initialState = {
            bots: [],
            botnames: [],
            stepResult: [],
            range: false,
            pricipal: 1000,
            running: false,
            result: '',
            complete: false,
            visible: false,
            running_data: [],
            showResults: false,
            data: {
                plot: [
                    {
                        data: [[0, 0]]
                    }
                ]
            },
            wins: 0,
            losses: 0,
            winrate: 0,
            trades: [],
            percentage: 0,
            buys: []
        };
        return initialState;
    }

    handleForm = (key: any, value: any) => {
        if (key === 'bot') {
            this.props.setActiveBot(value);
        }
        let newState: any = this.state;
        newState.form[key] = value;
        this.setState(newState);
    };

    resetAll = () => {
        this.setState(prev => ({
            ...prev,
            ...{
                bots: [],
                botnames: [],
                stepResult: [],
                range: false,
                pricipal: 1000,
                running: false,
                result: '',
                complete: false,
                visible: false,
                running_data: [],
                showResults: false,
                data: {
                    plot: []
                },
                wins: 0,
                losses: 0,
                winrate: 0,
                trades: [],
                percentage: 0,
                buys: [],
                renderResults: false,
                runStart: false
            }
        }));
    };

    sendBacktest = () => {
        let newState: any = this.state;
        newState.running = true;
        newState.runStart = true;
        newState.error = false;
        newState.showResults = true;
        this.setState(newState);

        let payload = (this.state as any).form;

        if (payload['commission'] === undefined) {
            payload['commission'] = 0;
        }
        if (payload['Principal'] === undefined) {
            payload['Principal'] = 1000;
        }
        payload['bot']['marketSelectionMethod'] = 'manual';
        payload['bot']['marketSelection'] = (this.state as any).asset;
        payload['userId'] = auth.currentUser && auth.currentUser.uid;
        let url = `${host}/backtest`;
        axios.post(url, payload).then(
            response => {
                let newState: any = this.state;
                console.log(response);
                newState.data = JSON.parse(response.data);
                newState.renderResults = true;

                if (response.data.error) {
                    let newState: any = this.state;
                    newState.running = false;
                    newState.error = true;
                    newState.showResults = false;
                    this.setState(newState);
                    message.error(newState.data.error);
                    return;
                } else {
                    if (
                        newState.data['Wins'] === 0 &&
                        newState.data['Losses'] === 0 &&
                        newState.data['Return'] === 0
                    ) {
                        message.warning(
                            'Your bot did not make any trades. Try changing the period and principal.'
                        );
                    } else {
                        message.success('Backtest complete');
                    }
                    this.props.setCollapsed(true);
                    let calendar = this.createPerformanceCalendar();
                    newState.calendar = calendar;
                    newState.running = false;
                    console.log(newState);
                    this.setState(newState);
                }
            },
            error => {
                let newState: any = this.state;
                newState.running = false;
                newState.error = true;
                newState.showResults = false;
                this.setState(newState);
                console.log(error);

                message.error('There was an error. Please try again later.');
            }
        );
    };

    renderResults = () => {
        return <p>None</p>;
    };

    generateTrades = () => {
        let finalTrades: any = [];
        if (Object.keys((this.state as any).data).includes('trades')) {
            (this.state as any).data['trades'].forEach((d: any) => {
                if (Object.keys(d).includes('sell')) {
                    let data = {
                        open: d['buy']['date'],
                        Date: d['sell']['date'],
                        Shares: d['sell']['Shares'],
                        open_price: d['buy']['Execution'],
                        Execution: d['sell']['Execution'],
                        PNL: d['sell']['PNL'],
                        Security: d['buy']['Security']
                    };
                    finalTrades.push(data);
                }
            });
        }
        return finalTrades;
    };

    render() {
        return (
            <div>
                <PageHeader
                    className='site-page-header'
                    onBack={this.reset}
                    title={
                        <>
                            <span style={{ marginRight: 8 }}>Back Testing</span>
                            {(this.state as any).runStart && (
                                <>
                                    {(this.state as any).running ? (
                                        <Tag color='blue' icon={<LoadingOutlined spin />}>
                                            Running
                                        </Tag>
                                    ) : (
                                        <>
                                            {(this.state as any).error ? (
                                                <Tag color='red' icon={<CloseCircleOutlined />}>
                                                    Error
                                                </Tag>
                                            ) : (
                                                <Tag color='green' icon={<CheckCircleOutlined />}>
                                                    Done
                                                </Tag>
                                            )}
                                        </>
                                    )}
                                    {!(this.state as any).running && (
                                        <Button
                                            // type='secondary'
                                            icon={<EditOutlined />}
                                            onClick={() => {
                                                this.setState({ quickEditOpen: true });
                                            }}
                                        >
                                            Quick Edit
                                        </Button>
                                    )}
                                </>
                            )}
                        </>
                    }
                />
                <QuickEdit
                    visible={(this.state as any).quickEditOpen}
                    onClose={() => {
                        this.setState({ quickEditOpen: false });
                    }}
                    onComplete={strategy => {
                        this.setState(
                            {
                                form: {
                                    ...(this.state as any).form,
                                    bot: strategy
                                },
                                quickEditOpen: false
                            },
                            this.sendBacktest
                        );
                    }}
                    strategy={(this.props as any).strategy}
                    readonly={false}
                />
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 14 }}
                    onFinish={this.sendBacktest}
                    initialValues={{
                        commission: 0.0
                    }}
                >
                    {(this.state as any).showResults ? (
                        <></>
                    ) : (
                        <BackTesterForm
                            strategy={(this.props as any).strategy}
                            strategies={(this.props as any).userData.strategies}
                            handleForm={this.handleForm}
                            role={(this.props as any).userData.role}
                            test={(this.props as any).userData.tests}
                            asset={(this.state as any).asset}
                            setAsset={asset => this.setState({ asset: asset })}
                        />
                    )}

                    {(this.state as any).showResults ? (
                        <div style={{ marginTop: 20 }}>
                            {(this.state as any).renderResults ? (
                                <>
                                    <BackTestResults data={(this.state as any).data} />
                                </>
                            ) : (
                                <></>
                            )}

                            <Card style={{ marginTop: 30 }}>
                                {Object.keys(this.state).includes('data') ? (
                                    <Table
                                        columns={columns}
                                        dataSource={this.generateTrades()}
                                        size='small'
                                    />
                                ) : (
                                    <></>
                                )}
                            </Card>

                            {(this.state as any).error ? (
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                            ) : (
                                <></>
                            )}
                            <Button
                                type='primary'
                                icon={<ReloadOutlined />}
                                style={{ marginTop: 20, marginBottom: 20 }}
                                onClick={this.reset}
                            >
                                New Test
                            </Button>
                        </div>
                    ) : (
                        <></>
                    )}
                </Form>
                <Drawer
                    title='Quick Edit'
                    placement='right'
                    width={window.innerWidth / 2}
                    closable={true}
                    onClose={() => {
                        let newState: any = this.state;
                        newState.visible = false;
                        this.setState(newState);
                    }}
                    visible={(this.state as any).visible}
                />
            </div>
        );
    }
}
