import React from 'react';
import { Card, Row, Col, Statistic } from 'antd';

import { Tooltip } from 'antd';

import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { IBacktest } from '../../../models/api';
import { isFunction } from 'util';

interface StatisticsProps {
    data: IBacktest;
}

const rowStyle = {};

const colStyle = {};

const titleSpan = 12;
const contentSpan = 12;

const titles = {
    return: 'Total Portfolio Return (%)',
    equity_final: 'Final Portfolio Value',
    win_rate: 'Win Rate',
    alpha: 'Alpha',
    wins: 'Total Winning Trades',
    losses: 'Total Losing Trades',
    equity_peak: 'Peak Portfolio Value',
    holding_return: 'Return If Holding Underlying',
    sharpe: 'Sharpe Ratio',
    max_win: 'Largest Winning Trade',
    max_loss: 'Largest Losing Trade',
    max_drawdown: 'Max Drawdown'
};

const presets = {
    return: 'percentWithArrow',
    equity_final: 'currency',
    win_rate: 'percent',
    alpha: 'percentWithArrow',
    wins: 'naturalNumber',
    losses: 'naturalNumber',
    equity_peak: 'currency',
    holding_return: 'percentWithArrow',
    sharpe: 'number',
    max_win: 'percent',
    max_loss: 'percent',
    max_drawdown: 'percent'
};

export default function Statistics({ data }: StatisticsProps) {
    const formatter = (
        value: number,
        template?: 'percent' | 'currency' | 'percentWithArrow' | 'number' | 'naturalNumber'
    ) => {
        switch (template) {
            case 'percent':
                return parseFloat((value * 100).toFixed(2)).toLocaleString() + '%';
            case 'naturalNumber':
                return parseFloat(value.toFixed(0)).toLocaleString();
            case 'percentWithArrow':
                return (
                    <span style={{ color: value > 0 ? '#79d24c' : '#ff4d4f' }}>
                        {value >= 0 ? <UpOutlined /> : <DownOutlined />}{' '}
                        {parseFloat((value * 100).toFixed(2)).toLocaleString() + '%'}
                    </span>
                );
            case 'currency':
                return '$' + parseFloat(value.toFixed(2)).toLocaleString();
            default:
                if (value === null) {
                    return 'N/A';
                }
                return value.toFixed(2);
        }
    };

    return (
        <>
            <Row style={rowStyle}>
                <Col style={colStyle} span={titleSpan} />
                <Col style={colStyle} span={contentSpan} />
            </Row>
            {Object.keys(presets).map(key => (
                <Row style={rowStyle}>
                    <Col style={colStyle} span={titleSpan}>
                        {(titles as any)[key]}
                    </Col>
                    <Col style={colStyle} span={contentSpan}>
                        {formatter((data as any)[key], (presets as any)[key])}
                    </Col>
                </Row>
            ))}
        </>
    );
}
