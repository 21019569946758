import React from 'react';
import { Drawer, message } from 'antd';
import { isMobile } from 'react-device-detect';
import { IStrategy } from '../../../models/strategy';
import StrategyBuilder from '.';
import { PropertySafetyFilled } from '@ant-design/icons';

interface QuickEditProps {
    visible: boolean;
    onClose: () => void;
    strategy: IStrategy;
    onComplete: (strategy: IStrategy) => void;
    readonly?: boolean;
}

const QuickEdit = ({ visible, onClose, strategy, onComplete, readonly }: QuickEditProps) => {
    return (
        <Drawer visible={visible} onClose={onClose} width={isMobile ? '100%' : '65%'}>
            <StrategyBuilder
                quickedit
                strategy={strategy}
                refreshUser={() => { }}
                setCurrentKey={() => { }}
                handleBotBackTest={() => { }}
                pushStrategy={onComplete}
                readonly={readonly}
            />
        </Drawer>
    );
};

export default QuickEdit;
