import React, { useEffect, useState } from 'react';
import { fetchUser } from '../base';
import { IUser } from '../models/firebase';
import { Layout, Affix, Menu, Avatar } from 'antd';
import DashMenu from '../components/Dashboard/DashMenu';
import { isMobile } from 'react-device-detect';
import Footer from '../components/Footer';
import MyBots from '../components/Dashboard/MyBots';
import StrategyBuilder from '../components/Dashboard/StrategyBuilder';
import Account from '../components/Dashboard/User/Account';
import { IStrategy } from '../models/strategy';
import BackTester from '../components/Dashboard/Backtester/BackTester';
import Feedback from '../components/Dashboard/Feedback';

const preloaders = require('react-preloaders');
const { Sugar } = preloaders;


function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
}

const { Sider, Content, Header } = Layout;

const Dashboard: React.FC = () => {
    //user data
    const [user, setUser] = useState<IUser | null>();
    const [windowSize, setWindowSize] = useState(getWindowSize());
    const [userStatus, setUserStatus] = useState('');

    console.log(user);


    useEffect(() => {
        if (user) {
            setUserStatus(user.role);
        }
    })

    //menu
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [currentKey, setCurrentActiveKey] = useState<number>(0);

    const setCurrentKey = (key: number, noclear: boolean = false) => {
        if (!noclear) {
            setActiveBot(null);
        }
        setCurrentActiveKey(key);
    };

    //content
    const [botEdit, setBotEdit] = useState<boolean>(false);
    const [activeBot, setActiveBot] = useState<IStrategy | null>(null);

    useEffect(() => {
        function handleWindowResize() {
            setWindowSize(getWindowSize());
        }
        window.addEventListener('resize', handleWindowResize);

        !user && refreshUser();

        return () => {
            window.removeEventListener('resize', handleWindowResize);
        };
    });

    const refreshUser = () => {
        fetchUser().then(([hang, user]) => {
            if (user && !hang) {
                console.log('fired')
                setUser({ ...user });
            }
        });
    };

    const handleBotEditInitiation = (bot: IStrategy) => {
        setActiveBot(bot);
        setBotEdit(true);
        setCurrentKey(1, true);
    };

    const handleBotBackTest = (bot: IStrategy) => {
        setActiveBot(bot);
        setCurrentKey(4, true);
    };

    const pushStrategy = (s: IStrategy) => {
        if (!user?.strategies) return;
        setUser({
            ...user, strategies: [...user.strategies, s]
        })
        setActiveBot(s);
    }

    const renderView = (key: number) => {
        if (user) {
            switch (key) {
                case 0:
                    return (
                        <MyBots
                            strategies={user.strategies}
                            onViewChange={setCurrentKey}
                            onBotEdit={handleBotEditInitiation}
                            refreshUser={refreshUser}
                        />
                    );
                case 1:
                    return (
                        <StrategyBuilder
                            {...activeBot && { strategy: activeBot }}
                            refreshUser={refreshUser}
                            setCurrentKey={setCurrentKey}
                            handleBotBackTest={handleBotBackTest}
                            pushStrategy={pushStrategy}
                            userStatus={userStatus}
                        />
                    );
                case 3:
                    return <Account userData={user} onChange={setCurrentKey} />;
                case 4:
                    return (
                        <>
                            <BackTester
                                refreshAccount={refreshUser}
                                userData={{ ...user }}
                                setCollapsed={setCollapsed}
                                onChange={setCurrentKey}
                                index={0}
                                setActiveBot={setActiveBot}
                                {...activeBot && { strategy: activeBot }}

                            />
                        </>
                    );
                case 6:
                    return <>
                        <Feedback /></>
            }
        }
    };

    return (
        <>
            {user ? (
                <Layout style={{ padding: '0px !important' }}>
                    <Sider
                        collapsible
                        collapsed={collapsed}
                        onCollapse={() => setCollapsed(!collapsed)}
                        theme='light'
                        width={250}
                        style={{
                            position: 'sticky',
                            top: 0,
                            height: '100vh',
                            overflow: 'auto'
                        }}
                    >
                        <DashMenu
                            user={user}
                            collapsed={collapsed}
                            currentKey={currentKey}
                            onChange={setCurrentKey}
                            height={window.innerHeight}
                            name={user.firstName + ' ' + user.lastName}
                        />
                    </Sider>
                    <Affix
                        style={{
                            marginLeft: collapsed ? 80 : 250,
                            width: `calc(100% - 256px)`,
                            position: 'absolute',
                            top: 0,
                            height: 65
                        }}
                    >
                        <Header style={{ padding: 0, userSelect: 'none' }}>
                            <Menu
                                mode='horizontal'
                                theme='light'
                                selectedKeys={['999']}
                                style={{
                                    width:
                                        // '100%',
                                        collapsed
                                            ? windowSize.innerWidth - 80
                                            : windowSize.innerWidth - 250,
                                    justifyContent: 'flex-end'
                                }}
                            >
                                <Menu.Item
                                    key='8'
                                    onClick={() => setCurrentKey(3)}
                                    style={{
                                        float: 'right',
                                        height: 65
                                    }}
                                >
                                    <Avatar>{(user.firstName + ' ' + user.lastName)[0]}</Avatar>
                                    <span style={{ marginLeft: 10 }}>
                                        {user.firstName + ' ' + user.lastName}
                                    </span>
                                </Menu.Item>
                            </Menu>
                        </Header>
                    </Affix>
                    <Content
                        style={{
                            paddingLeft: isMobile ? 0 : 80,
                            paddingTop: 60,
                            paddingRight: isMobile ? 0 : 20,
                            backgroundColor: 'white',
                            marginTop: isMobile ? 10 : 20
                        }}
                    >
                        <div style={{
                            height: 'calc(100vh - 130px)', overflowY: 'scroll', paddingRight: '10px', paddingLeft: '10px'
                        }}>
                            {renderView(currentKey)}
                        </div>
                        <Footer />
                    </Content>
                </Layout>
            ) : <Sugar customLoading={user} time={0} color={"#FFFFFF"} background="#1890FF" />
            }
        </>
    );
};
export default Dashboard;
