import React from 'react';
import {
    Card,
    Tag,
    Select,
    Form,
    Checkbox,
    InputNumber,
    Row,
    Col,
    Button,
    DatePicker,
    Alert,
    Divider
} from 'antd';

import { LoadingOutlined, WarningOutlined } from '@ant-design/icons';
import { IStrategy } from '../../../models/strategy';
import { doCreateCheckout } from '../../../base';
import { AssetClass } from '../../../models/api';
import AssetSelectionModal from '../StrategyBuilder/CollapsbileSection/AssetSelectionModal';
const { RangePicker } = DatePicker;

const { Option } = Select;

interface BackTesterFormProps {
    strategies: Array<IStrategy>;
    strategy?: IStrategy;
    handleForm: (key: string, value: any) => void;
    role?: string;
    test?: Array<any>;
    asset: any;
    setAsset: (asset: any) => void;
}

export default class BackTesterForm extends React.Component<BackTesterFormProps> {
    state = {
        range: false,
        selected_bot: this.props.strategy ? this.props.strategy.id : null,
        running: false,
        form: {
            slippage: false
        },
        disabled: false,
        loading: false
    };

    componentDidMount() {
        if (this.props.strategy) {
            this.props.handleForm('bot', this.props.strategy);
        }
        if (this.props.role !== 'premium') {
            const today = new Date();
            const testsInCurrentMonth = (this.props.test || []).filter(x => {
                const dateNow = new Date(x.date.seconds * 1000);
                if (
                    dateNow.getFullYear() === today.getFullYear() &&
                    dateNow.getMonth() === today.getMonth()
                ) {
                    return true;
                }
                return false;
            });
            if (testsInCurrentMonth.length >= 5) {
                this.setState({ disabled: true });
            }
        }
    }

    render() {
        return (
            <Card>
                <Form.Item name='strategy' label={<span>Strategy</span>}>
                    <Select
                        style={{ width: 300 }}
                        value={this.state.selected_bot}
                        defaultValue={this.state.selected_bot}
                        onChange={(e: any) => {
                            this.props.handleForm(
                                'bot',
                                this.props.strategies.find(x => e === x.id)
                            );
                            this.setState({ selected_bot: e });
                        }}
                    >
                        {this.props.strategies.map((bot: IStrategy) => (
                            <Option value={bot.id} key={bot.name}>
                                {bot.name}{' '}
                                {/* {bot.marketSelectionMethod === 'screener' ? (
                                    <Tag color='red'>Screener</Tag>
                                ) : (
                                    <></>
                                )} */}
                            </Option>
                        ))}
                    </Select>
                </Form.Item>
                <AssetSelectionModal
                    asset={this.props.asset}
                    setAsset={asset => this.props.setAsset(asset)}
                />
                <Divider />
                <Form.Item
                    name='Principal'
                    label={<span>Principal</span>}
                    rules={[{ required: true, type: 'number', min: 0 }]}
                >
                    <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: string | undefined) =>
                            value ? value.replace(/\$\s?|(,*)/g, '') : ''
                        }
                        style={{ width: 300 }}
                        onChange={e => this.props.handleForm('principal', e)}
                    />
                </Form.Item>
                <Form.Item
                    name='commission'
                    label='Commission'
                    rules={[{ required: true, type: 'number', min: 0 }]}
                >
                    <InputNumber
                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value: string | undefined) =>
                            value ? value.replace(/\$\s?|(,*)/g, '') : ''
                        }
                        onChange={e => this.props.handleForm('commission', e)}
                    />
                </Form.Item>
                {this.state.range ? (
                    <div>
                        <Form.Item
                            name='start'
                            label={<span>Backtesting Range</span>}
                            rules={[{ required: true }]}
                        >
                            <RangePicker
                                picker='date'
                                onChange={(e, v) => this.props.handleForm('daterange', v)}
                            />
                        </Form.Item>
                    </div>
                ) : (
                    <div>
                        <Form.Item
                            name='period'
                            label={<span>Period</span>}
                            rules={[{ required: true }]}
                        >
                            <Select
                                style={{ width: 300 }}
                                onChange={e => this.props.handleForm('daterange', e)}
                            >
                                <Option value='1d'>One Day</Option>
                                <Option value='5d'>Five Days</Option>
                                <Option value='1mo'>One Month</Option>
                                <Option value='3mo'>Three Months</Option>
                                <Option value='6mo'>Six Month</Option>
                                <Option value='1y'>One Year</Option>
                                <Option value='2y'>Two Years</Option>
                                <Option value='5y'>Five Years</Option>
                                <Option value='ytd'>YTD</Option>
                                <Option value='max'>Max</Option>
                            </Select>
                        </Form.Item>
                        {/* <p>
                                <WarningOutlined /> Bots with 1 minute frequencies can only be
                                tested on the past 7 days.
                            </p> */}
                    </div>
                )}
                {/* </Card>
                    </Col>
                </Row> */}
                <Form.Item name='slippage' label='Enable Slippage'>
                    <Checkbox
                        defaultChecked={true}
                        onChange={e => {
                            let newState = this.state;
                            newState.form.slippage = e.target.checked;
                            this.setState(newState);
                        }}
                    />
                </Form.Item>
                <Form.Item>
                    <Col offset={5}>
                        <Row style={{ paddingBottom: 15 }}>
                            {this.state.disabled && (
                                <Alert
                                    message={
                                        <>
                                            You have reached your back test limit for this month.
                                            Want unlimited back tests?{' '}
                                            <a
                                                style={{ color: '#1990FF' }}
                                                onClick={async () => {
                                                    this.setState({ loading: true });
                                                    await doCreateCheckout();
                                                }}
                                            >
                                                Trial Premium Features{' '}
                                                {this.state.loading && <LoadingOutlined />}
                                            </a>
                                        </>
                                    }
                                    type='info'
                                />
                            )}
                        </Row>
                    </Col>
                    <Row>
                        <Col offset={5}>
                            <Button htmlType='submit' type='primary' disabled={this.state.disabled}>
                                {' '}
                                {this.state.running ? <span>Running</span> : <span>Test</span>}
                                {this.state.running ? <LoadingOutlined spin /> : <span />}
                            </Button>
                        </Col>
                    </Row>
                </Form.Item>
            </Card>
        );
    }
}
