import React from 'react';
import { PageHeader } from '@ant-design/pro-layout';
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form';

const Feedback = () => {
    const { loaded, error, formCreated } = useHubspotForm({
        portalId: '22827895',
        formId: '5d42f195-6bc0-41e5-b7fb-c4b446a192e5',
        target: '#my-hubspot-form'
    });
    return (
        <>
            <PageHeader title='Feedback' onBack={() => {}} />
            <div style={{ padding: 30 }} id='my-hubspot-form' />
        </>
    );
};

export default Feedback;
