import React, { useState, useEffect, useRef } from 'react';
import { Row, Col, Tag } from 'antd';
import { CrownOutlined } from '@ant-design/icons';
import { doCreateCheckout } from '../../../../base';
import {
    triggers,
    Trigger,
    TriggerDomain,
    TriggerCategory,
    TriggerCategoryTitles
} from '../triggers';
import { PlusOutlined, DownOutlined, RightOutlined } from '@ant-design/icons';

interface TriggerAddDropDownProps {
    title: string;
    searchString: string;
    setSearchString: (searchString: string) => void;
    addTrigger: (trigger?: Trigger) => void;
    activeModifier: 'or' | 'and';
    setActiveModifier: (modifier: 'or' | 'and') => void;
    selectedTriggers: Array<Trigger>;
    userStatus?: string;
}

const TriggerAddDropDown = (props: TriggerAddDropDownProps) => {
    const [addActive, setAddActive] = useState<boolean>(false);
    const { searchString, setSearchString, addTrigger } = props;
    const [menuItemsOpen, setMenuItemsOpen] = useState<{ [key: string]: boolean }>(
        Object.keys(TriggerCategoryTitles).reduce((o, key) => ({ ...o, [key]: true }), {})
    );

    const { title } = props;

    const getStyles = (trigger: Trigger) => {
        const shared: React.CSSProperties = {
            padding: 4
        };

        const disabled: React.CSSProperties = {
            ...shared,
            cursor: 'not-allowed',
            color: 'lightgrey'
        };

        const enabled: React.CSSProperties = {
            ...shared,
            cursor: 'pointer'
        };

        const premium: React.CSSProperties = {
            ...shared,
            ...disabled
        };

        if (trigger.domain == TriggerDomain.Exit && title.includes('Entry')) {
            return disabled;
        }

        if (props.userStatus == 'free' && trigger.category === 'pattern') {
            return premium;
        }

        return enabled;
    };

    const ref = useRef<any>(null);

    useEffect(
        () => {
            const handleClickOutside = (event: any) => {
                if (ref.current && !ref.current.contains(event.target) && addActive !== false) {
                    setAddActive(false);
                    setSearchString('');
                }
            };
            document.addEventListener('click', handleClickOutside, true);
            return () => {
                document.removeEventListener('click', handleClickOutside, true);
            };
        },
        [addActive]
    );

    return (
        <div style={{ paddingTop: 10 }} ref={ref}>
            {addActive ? (
                <>
                    <Row
                        style={{
                            border: '2px solid #1990FF',
                            borderRadius: 6,
                            padding: 6,
                            backgroundColor: 'white'
                        }}
                    >
                        <Col>
                            <span
                                style={{
                                    fontWeight: 700,
                                    fontSize: 12,
                                    backgroundColor: '#34eb98',
                                    borderRadius: 4,
                                    color: 'white',
                                    padding: 4
                                }}
                            >
                                {props.selectedTriggers.length === 0
                                    ? 'IF'
                                    : props.activeModifier.toUpperCase()}
                            </span>
                        </Col>
                        <Col span={10} style={{ paddingLeft: 10 }}>
                            <input
                                id='triggerSearch'
                                autoFocus
                                placeholder='Search triggers...'
                                onChange={e => setSearchString(e.target.value)}
                                onKeyDown={e => e.key === 'Enter' && addTrigger()}
                                value={searchString}
                            />
                        </Col>
                    </Row>
                    <div
                        style={{
                            backgroundColor: 'white',
                            maxHeight: 250,
                            overflow: 'auto',
                            padding: 9
                        }}
                    >
                        {Object.values(TriggerCategory).map(key => (
                            <>
                                {triggers.filter(
                                    (item: Trigger) =>
                                        item.title
                                            .toLowerCase()
                                            .includes(searchString.toLowerCase()) &&
                                        item.category == key
                                ).length > 0 && (
                                    <Row
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => {
                                            setMenuItemsOpen({
                                                ...menuItemsOpen,
                                                [key]: !menuItemsOpen[key]
                                            });
                                        }}
                                    >
                                        <Col style={{ paddingRight: 8 }}>
                                            {menuItemsOpen[key] || searchString.length ? (
                                                <DownOutlined style={{ fontSize: 10 }} />
                                            ) : (
                                                <RightOutlined style={{ fontSize: 10 }} />
                                            )}
                                        </Col>
                                        <Col span={23} className='triggeraddhover'>
                                            <span style={{ fontSize: 12 }}>
                                                {TriggerCategoryTitles[key]}
                                            </span>
                                        </Col>
                                    </Row>
                                )}
                                {(menuItemsOpen[key] || searchString.length > 0) &&
                                    triggers
                                        .filter(
                                            (item: Trigger) =>
                                                item.title
                                                    .toLowerCase()
                                                    .includes(searchString.toLowerCase()) &&
                                                item.category === key
                                        )
                                        .map((trigger: Trigger) => (
                                            <Row
                                                style={getStyles(trigger)}
                                                className='triggeraddhover'
                                                key={trigger.id}
                                            >
                                                <Col style={{ marginLeft: 20 }}>
                                                    {props.userStatus == 'free' &&
                                                    trigger.category === 'pattern' ? (
                                                        <>
                                                            <span
                                                                style={{
                                                                    fontSize: 12,
                                                                    marginRight: '25px'
                                                                }}
                                                                onClick={() => {
                                                                    addTrigger(trigger);
                                                                    setAddActive(false);
                                                                }}
                                                            >
                                                                {trigger.title}
                                                            </span>
                                                            <Tag
                                                                style={{ cursor: 'pointer' }}
                                                                onClick={async () => {
                                                                    await doCreateCheckout();
                                                                }}
                                                                color='processing'
                                                            >
                                                                Premium <CrownOutlined />
                                                            </Tag>
                                                        </>
                                                    ) : (
                                                        <span
                                                            style={{
                                                                fontSize: 12,
                                                                marginRight: '25px'
                                                            }}
                                                            onClick={() => {
                                                                addTrigger(trigger);
                                                                setAddActive(false);
                                                            }}
                                                        >
                                                            {trigger.title}
                                                        </span>
                                                    )}
                                                </Col>
                                            </Row>
                                        ))}
                            </>
                        ))}
                        {/* {triggers                                   // Ready element:{trigger.title} <Tag color='blue'>Premium</Tag> <CrownOutlined /> </> 
                            .filter((item: Trigger) =>
                                item.title.toLowerCase().includes(searchString.toLowerCase())
                            )
                            .map((trigger: Trigger) => (
                                <>
                                    <Row style={getStyles(trigger)}>
                                        <Col>
                                            <span
                                                style={{ fontSize: 12 }}
                                                onClick={() => addTrigger(trigger)}
                                            >
                                                {trigger.title}
                                            </span>
                                        </Col>
                                    </Row>
                                </>
                            ))} */}
                    </div>
                </>
            ) : (
                <Row>
                    <div
                        id='selectAssets'
                        style={{
                            fontSize: 12,
                            paddingLeft: 10,
                            fontWeight: 700,
                            cursor: 'pointer'
                        }}
                        onClick={() => setAddActive(true)}
                    >
                        <PlusOutlined style={{ marginRight: 5 }} />
                        New Condition
                    </div>
                </Row>
            )}
        </div>
    );
};
export default TriggerAddDropDown;
