import React, { useCallback, useState } from 'react';
import { Row, Col, Form, Input, Button, Alert } from 'antd';
import { useNavigate } from 'react-router';
import { resetPasswordWithEmail } from '../base';

import blue from '../images/blue.jpg';
import logowhite from '../images/white.png';

interface IPasswordResetAlert {
    visible: boolean;
    msg: string;
    type: 'success' | 'error' | 'info' | 'warning' | undefined;
}

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const [resetPassAlert, setResetPassAlert] = useState<IPasswordResetAlert>({
        visible: false,
        msg: '',
        type: undefined
    });
    const handleResetPassword = useCallback(
        async values => {
            const res: any = await resetPasswordWithEmail(values['email']);
            console.log('RES ==>', res);
            setResetPassAlert({
                visible: true,
                msg: res.msg,
                type: res.response ? 'success' : 'error'
            });
        },
        [navigate]
    );

    const resetAlert = () => {
        setResetPassAlert({ visible: false, msg: '', type: undefined });
    };

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#F7F7F7'
            }}
        >
            {/* <Header /> */}
            <Row style={{ height: '43%' }}>
                <Col
                    lg={{ span: 12 }}
                    xs={{ span: 0 }}
                    style={{
                        backgroundImage: `url(${blue})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        color: 'white'
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'rgba(24,144,255, 0.8)',
                            padding: 40,
                            height: '100%'
                        }}
                    >
                        <Row>
                            <Col>
                                <img src={logowhite} height={60} />
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 70 }}>
                            <h2 style={{ color: 'white' }}>Reset Password</h2>
                        </Row>
                        <Row>
                            <span>
                                Build strategies and discover alpha without risking capital.
                            </span>
                        </Row>
                    </div>
                </Col>
                <Col
                    lg={{ span: 12 }}
                    xs={{ span: 24, offset: 0 }}
                    style={{
                        padding: 45,
                        backgroundColor: 'white'
                    }}
                >
                    {resetPassAlert.visible ? (
                        <Alert
                            message={resetPassAlert.msg}
                            type={resetPassAlert.type}
                            closable
                            onClose={resetAlert}
                        />
                    ) : null}
                    <Form
                        name='basic'
                        initialValues={{ remember: true }}
                        onFinish={handleResetPassword}
                        autoComplete='off'
                    >
                        <Form.Item>
                            <h2>Reset Password for Fulcher Analytics</h2>
                        </Form.Item>

                        <small>Email</small>
                        <Form.Item
                            name='email'
                            rules={[{ required: true, message: 'Please enter your email' }]}
                        >
                            <Input type='email' width={200} />
                        </Form.Item>

                        <small>
                            <a href='/login'>Already have an account? Login</a>
                        </small>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{ marginTop: 20, width: 100 }}
                            >
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default ForgotPassword;
