import { useState, useEffect } from 'react';
import { Modal, Radio, Row, Select, Button, Popover, Form } from 'antd';
import React from 'react';
import { AssetClass } from '../../../../models/api';

// selectable universe
import stocks from '../../../../utils/refdata.json';
import cryptos from '../../../../utils/products.json';

import { doCreateCheckout } from '../../../../base';
import { LoadingOutlined } from '@ant-design/icons';

const { Option } = Select;
interface AssetSelectionModalProps {
    asset: any;
    setAsset: (asset: any) => void;
    userStatus?: string;
    readonly?: boolean;
}

const AssetSelectionModal = ({ asset, setAsset, userStatus }: AssetSelectionModalProps) => {
    const [checkoutLoading, setCheckoutLoading] = useState(false);

    const content = (
        <div>
            <p>This asset class is only available in Premium Membership.</p>
            <Button
                type='primary'
                onClick={async () => {
                    setCheckoutLoading(true);
                    await doCreateCheckout();
                }}
            >
                Upgrade Membership {checkoutLoading && <LoadingOutlined />}
            </Button>
        </div>
    );

    const convertAssetClass = () => {
        if (asset.assetClass === AssetClass.EQUITY) {
            return 'stocks';
        } else if (asset.assetClass === AssetClass.CRYPTOCURRENCY) {
            return 'crypto';
        }
        return 'forex';
    };

    return (
        // <Modal visible={visible && !readonly} onCancel={onClose} onOk={onClose}>
        <>
            {/* <Row> */}
            <Form.Item label='Asset Class'>
                <Radio.Group
                    defaultValue='stocks'
                    buttonStyle='solid'
                    {...asset.assetClass && { value: convertAssetClass() }}
                    onChange={e => {
                        let assetClass;
                        if (e.target.value === 'stocks') {
                            assetClass = AssetClass.EQUITY;
                        } else if (e.target.value === 'crypto') {
                            assetClass = AssetClass.CRYPTOCURRENCY;
                        } else {
                            assetClass = AssetClass.FOREX;
                        }
                        setAsset({ ...asset, assetClass: assetClass, targetSymbol: null });
                    }}
                >
                    <Radio.Button value='stocks'>Stocks</Radio.Button>
                    {userStatus === 'free' ? (
                        <>
                            <Popover content={content} title='Upgrade Membership'>
                                <Radio.Button value='crypto' disabled>
                                    Cryptocurrency
                                </Radio.Button>
                            </Popover>
                        </>
                    ) : (
                        <Radio.Button value='crypto'>Cryptocurrency</Radio.Button>
                    )}

                    <Radio.Button value='forex' disabled>
                        Forex
                    </Radio.Button>
                </Radio.Group>
            </Form.Item>
            {/* </Row> */}
            <Form.Item label='Asset'>
                {asset.assetClass === AssetClass.EQUITY ? (
                    <Select
                        showSearch
                        style={{ width: 300 }}
                        placeholder='Select a security'
                        {...asset.targetSymbol && { value: asset.targetSymbol }}
                        onSelect={(ticker: string) => setAsset({ ...asset, targetSymbol: ticker })}
                        optionFilterProp='title'
                        filterOption={(input, option) => {
                            return ((option!.title as unknown) as string)
                                .toLowerCase()
                                .includes(input.toLowerCase());
                        }}
                    >
                        {stocks.map(stock => (
                            <Option value={stock.Symbol} title={stock.Symbol + ' ' + stock.Issuer}>
                                <strong>{stock.Symbol}</strong> {stock.Issuer}
                            </Option>
                        ))}
                    </Select>
                ) : userStatus === 'free' ? (
                    asset.assetClass === AssetClass.EQUITY
                ) : (
                    <Select
                        showSearch
                        style={{ width: 300 }}
                        placeholder='Select a trading pair'
                        {...asset.targetSymbol && { value: asset.targetSymbol }}
                        onSelect={(pair: string) => setAsset({ ...asset, targetSymbol: pair })}
                        optionFilterProp='title'
                        filterOption={(input, option) => {
                            return ((option!.title as unknown) as string)
                                .toLowerCase()
                                .includes(input.toLowerCase());
                        }}
                    >
                        {cryptos
                            .filter(crypto => !crypto.symbol.includes('future'))
                            .map(crypto => (
                                <Option
                                    value={crypto.symbol}
                                    title={
                                        crypto.base.symbol.toUpperCase() +
                                        '/' +
                                        crypto.quote.symbol.toUpperCase()
                                    }
                                >
                                    {crypto.base.name} (
                                    {crypto.base.symbol.toUpperCase() +
                                        '/' +
                                        crypto.quote.symbol.toUpperCase()}
                                    )
                                </Option>
                            ))}
                    </Select>
                )}
            </Form.Item>
            <Form.Item label='Trading Frequency'>
                <Select
                    style={{ width: 300 }}
                    placeholder='Select trading frequency'
                    {...asset.frequency && { value: asset.frequency }}
                    onSelect={(freq: string) => setAsset({ ...asset, frequency: freq })}
                >
                    {/* <Option value='1m'>1 Minute</Option>
                    <Option value='2m'>2 Minute</Option>
                    <Option value='5m'>5 Minute</Option>
                    <Option value='15m'>15 Minute</Option>
                    <Option value='30m'>30 Minute</Option>
                    <Option value='60m'>60 Minute</Option>
                    <Option value='90m'>90 Minute</Option>
                    <Option value='1h'>1 Hour</Option> */}
                    <Option value='1d'>1 Day</Option>
                    <Option value='5d'>5 Day</Option>
                    <Option value='1wk'>1 Week</Option>
                </Select>
            </Form.Item>
        </> // </Modal>
    );
};

export default AssetSelectionModal;
