import { DataSourceItemObject } from 'antd/lib/auto-complete';

export enum AccountType {
    FIAT = 'Fiat',
    BITCOIN = 'Bitcoin',
    ETHEREUM = 'Ethereum'
}

export interface IPosition {
    identifier: string;
    type: AssetClass;
    timestamp: string;
    size: number;
    simulated: boolean;
    monetaryValue: number;
}

export interface IIntermediaryWallet {
    id: number;
    address: string;
    blockchain: string;
}

export enum AccountStatus {
    GOOD_STANDING = 'GOOD_STANDING',
    SUSPENDED = 'SUSPENDED',
    TERMINATED = 'TERMINATED'
}

export interface ITransaction {
    transactionType: 'IN' | 'OUT';
    party: string;
    identifier: string;
    qty: number;
    timestamp: string;
    simulated: boolean;
}

export interface IAccount {
    accountIdentifier: string;
    accountStatus: AccountStatus;
    portfolioValue: {
        real: number;
        simulated: number;
    };
    intermediaryWallets: Array<IIntermediaryWallet>;
    positions: Array<IPosition>;
    transactions: Array<ITransaction>;
}

export interface IBotPreview {
    bot_name: string;
    performance: Array<any>;
    trading: Array<string>;
}

export interface ITradingAccount {
    account: IAccount;
    trades: Array<any>;
    bots: Array<IBotPreview>;
    finance: {
        allTimeReturn: number;
        timeseries: Array<number>;
    };
}

export enum AssetClass {
    EQUITY = 'EQUITY',
    CRYPTOCURRENCY = 'CRYPTOCURRENCY',
    FOREX = 'FOREX'
}

export type TimeSeriesChart = Array<{
    date: Date;
    [key: string]: any;
}>;

export type StandAloneChart = {
    title: string;
    type: 'line' | 'bar' | 'area';
    chart: TimeSeriesChart;
    dataKey: string;
};

export interface IBacktest {
    //statistics
    wins: number;
    losses: number;
    equity_final: number;
    equity_peak: number;
    return: number;
    holding_return: number;
    sharpe: number;
    alpha: number;
    max_loss: number;
    win_rate: number;
    max_win: number;
    max_drawdown: number;

    //main chart, with underlying price/indicators
    timeseries: TimeSeriesChart;
    timeseries_datum: Array<string>;
    portfolio: StandAloneChart;

    //supporting charts, such as portfolio value, or a momentum indicator.
    standalone: Array<StandAloneChart>;
}
