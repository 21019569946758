import React, { useEffect } from 'react';
import { Empty, Row, Col, Button, Card, Tag, message, Radio, Tooltip } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import {
    EditOutlined,
    DeleteOutlined,
    PlusOutlined,
    CopyOutlined,
    HourglassOutlined,
    EyeOutlined
} from '@ant-design/icons';

import { IStrategy } from '../../models/strategy';
import { createOrUpdateStrategy, db, deleteStrategy } from '../../base';
import { collection, getDocs } from 'firebase/firestore';
import QuickEdit from './StrategyBuilder/QuickEdit';

interface StrategiesProps {
    strategies: Array<IStrategy>;
    onViewChange: (key: number) => void;
    onBotEdit: (bot: IStrategy) => void;
    refreshUser: () => void;
}

const Strategy = (props: {
    strategy: IStrategy;
    footer: React.ReactNode;
    onEdit?: any;
    onClick?: () => void;
}) => {
    const { strategy, footer, onEdit, onClick } = props;

    return (
        <Row style={{ marginBottom: 20 }}>
            <Col span={24}>
                <Card
                    hoverable
                    onClick={() => {
                        onEdit && onEdit(strategy);
                        onClick && onClick();
                    }}
                >
                    <Row>
                        <Col span={8}>
                            <Row>
                                <Col
                                    style={{
                                        paddingTop: 4,
                                        paddingBottom: 5,
                                        paddingRight: 12
                                    }}
                                >
                                    <h2 style={{ lineHeight: 1 }}>
                                        {/* {strategy.name.slice(0, 20)}
                                        {strategy.name.length > 20 && '...'} */}
                                        {strategy.name}
                                    </h2>
                                </Col>
                            </Row>
                            {/* <Row style={{ paddingTop: 4 }}>
                                <Tag color='blue'>{strategy.marketSelection.assetClass}</Tag>
                            </Row> */}
                        </Col>
                        <Col span={6} style={{ paddingTop: 5 }}>
                            <Row>
                                <span
                                    style={{
                                        textDecoration: 'uppercase',
                                        color: 'grey',
                                        fontSize: 12
                                    }}
                                >
                                    ENTRY CONDITIONS
                                </span>
                            </Row>
                            <Row>
                                <h3>{strategy.strategy.entryConditions.length}</h3>
                            </Row>
                        </Col>
                        <Col span={6} style={{ paddingTop: 5 }}>
                            <Row>
                                <span
                                    style={{
                                        textDecoration: 'uppercase',
                                        color: 'grey',
                                        fontSize: 12
                                    }}
                                >
                                    EXIT CONDITIONS
                                </span>
                            </Row>
                            <Row>
                                <h3>{strategy.strategy.exitConditions.length}</h3>
                            </Row>
                        </Col>
                        {footer}
                    </Row>
                </Card>
            </Col>
        </Row>
    );
};

const Strategies = (props: StrategiesProps) => {
    const { strategies, onViewChange } = props;
    const [view, setView] = React.useState<'mystrategies' | 'samplestrategies'>('mystrategies');

    const [sampleStrategies, setSampleStrategies] = React.useState<Array<IStrategy>>([]);
    const [quickEditVisible, setQuickEditVisible] = React.useState(false);
    const [activeStrategy, setActiveStrategy] = React.useState<IStrategy | null>(null);

    const fetchStrategies = async () => {
        await getDocs(collection(db, 'samplestrategies')).then(querySnapshot => {
            setSampleStrategies(querySnapshot.docs.map(doc => doc.data() as IStrategy));
            console.log(sampleStrategies);
        });
    };

    const duplicateStrategy = async (strategy: IStrategy) => {
        let newStrategy = { ...strategy };
        newStrategy.id = Math.random() * 1000;
        newStrategy.name = 'Copy of ' + newStrategy.name;
        createOrUpdateStrategy(newStrategy).then(() => {
            message.success('Strategy duplicated');
            props.refreshUser();
            setView('mystrategies');
        });
    };

    useEffect(() => {
        if (!sampleStrategies.length) {
            fetchStrategies();
        }
    });

    return (
        <div>
            <PageHeader
                title={
                    <Row>
                        <Col>Strategies</Col>
                        <Col style={{ paddingLeft: 10 }}>
                            <Button onClick={() => onViewChange(1)}>
                                New
                                <PlusOutlined />
                            </Button>
                        </Col>
                    </Row>
                }
                onBack={() => onViewChange(0)}
            />
            {activeStrategy && (
                <QuickEdit
                    visible={quickEditVisible}
                    onClose={() => setQuickEditVisible(false)}
                    onComplete={() => {}}
                    strategy={activeStrategy}
                    readonly
                />
            )}
            <div style={{ paddingTop: 20, paddingBottom: 20 }}>
                <Radio.Group
                    defaultValue='mystrategies'
                    buttonStyle='solid'
                    value={view}
                    onChange={e => {
                        setView(e.target.value);
                    }}
                >
                    <Radio.Button value='mystrategies'>My Strategies</Radio.Button>
                    <Radio.Button value='samplestrategies'>Sample Strategies</Radio.Button>
                </Radio.Group>
            </div>
            {view === 'mystrategies' ? (
                <div style={{ userSelect: 'none' }}>
                    {strategies.length === 0 && (
                        <Empty
                            style={{ marginTop: 200 }}
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <span>
                                    No strategies yet.{' '}
                                    <a
                                        style={{ color: 'rgba(0, 0, 0, 0.25', fontWeight: 600 }}
                                        onClick={() => onViewChange(1)}
                                    >
                                        Create a strategy
                                    </a>{' '}
                                    or{' '}
                                    <a
                                        onClick={() => setView('samplestrategies')}
                                        style={{ color: 'rgba(0, 0, 0, 0.25', fontWeight: 600 }}
                                    >
                                        chose one from a sample.
                                    </a>
                                </span>
                            }
                        />
                    )}
                    {strategies.map((strategy: IStrategy) => (
                        <Strategy
                            strategy={strategy}
                            onEdit={props.onBotEdit}
                            footer={
                                <>
                                    <Col span={2} style={{ paddingTop: 10 }}>
                                        <Tooltip title='Edit strategy'>
                                            <Button
                                                shape='circle'
                                                icon={<EditOutlined />}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    props.onBotEdit(strategy);
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={2} style={{ paddingTop: 10 }}>
                                        <Tooltip title='Delete strategy'>
                                            <Button
                                                shape='circle'
                                                icon={<DeleteOutlined />}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    deleteStrategy(strategy.id).then(() => {
                                                        props.refreshUser();
                                                        message.info('Strategy removed');
                                                    });
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                </>
                            }
                        />
                    ))}
                </div>
            ) : (
                <>
                    {sampleStrategies.map((strategy: IStrategy) => (
                        <Strategy
                            onClick={() => {
                                setActiveStrategy(strategy);
                                setQuickEditVisible(true);
                            }}
                            strategy={strategy}
                            footer={
                                <>
                                    <Col span={2} style={{ paddingTop: 10 }}>
                                        <Tooltip title='Create Copy'>
                                            <Button
                                                shape='circle'
                                                icon={<CopyOutlined />}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    duplicateStrategy(strategy);
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                    <Col span={2} style={{ paddingTop: 10 }}>
                                        <Tooltip title='View Strategy'>
                                            <Button
                                                shape='circle'
                                                icon={<EyeOutlined />}
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    setActiveStrategy(strategy);
                                                    setQuickEditVisible(true);
                                                }}
                                            />
                                        </Tooltip>
                                    </Col>
                                </>
                            }
                        />
                    ))}
                </>
            )}
        </div>
    );
};

export default Strategies;
