import React, { useState } from 'react';
import { Row, Col, Button, message, Input } from 'antd';
import CollapsibleSection from './CollapsbileSection';
import { Trigger } from './triggers';
import { IStrategy, ManualMarketSelectionProps } from '../../../models/strategy';
import { AssetClass } from '../../../models/api';
import { ArrowRightOutlined, EditOutlined, LoadingOutlined } from '@ant-design/icons';
import { createOrUpdateStrategy } from '../../../base';
import { useParams } from 'react-router';
import { objectEquals } from '../../../utils';
import { PageHeader } from '@ant-design/pro-layout';

interface StrategyBuilderProps {
    strategy?: IStrategy //active when ediitng
    refreshUser: () => void
    setCurrentKey: (key: number) => void
    handleBotBackTest: (s: IStrategy) => void
    pushStrategy: (s: IStrategy) => void
    quickedit?: boolean
    userStatus?: string
    readonly?: boolean
}

const StrategyBuilder = ({ strategy, refreshUser, setCurrentKey, handleBotBackTest, pushStrategy, quickedit, userStatus, readonly }: StrategyBuilderProps) => {

    const [saveValidated, setSaveValidated] = useState<boolean>(strategy ? false : true);
    const [savePending, setSavePending] = useState<boolean>(false);
    //strategy
    const [strategyName, setStrategyName] = useState(strategy ? strategy.name : 'Unnamed Strategy');
    const [isEditingName, setIsEditingName] = useState<boolean>(false);
    // const [asset, setAsset] = useState(strategy ? strategy.marketSelection : { frequency: null, assetClass: AssetClass.EQUITY });

    /* ================= entry ================= */
    const [entryConditionsOpen, setEntryConditionsOpen] = useState<boolean>(true);
    const [entryConditions, setEntryConditions] = useState<Array<Trigger>>(strategy ? strategy.strategy.entryConditions : []);
    const [entryModifier, setActiveEntryModifier] = useState<'or' | 'and'>('or');

    const setEntryModifier = (modifier: 'and' | 'or') => {
        setEntryConditions(current => [
            ...current.map((trigger: Trigger) =>
                trigger.modifier ? { ...trigger, modifier: modifier } : trigger
            )
        ]);
        setActiveEntryModifier(modifier);
    };

    /* ================= exit ================= */
    const [exitConditionsOpen, setExitConditionsOpen] = useState<boolean>(true);
    const [exitConditions, setExitConditions] = useState<Array<Trigger>>(strategy ? strategy.strategy.exitConditions : []);
    const [exitModifier, setActiveExitModifier] = useState<'or' | 'and'>('or');

    const setExitModifier = (modifier: 'and' | 'or') => {
        setExitConditions(current => [
            ...current.map((trigger: Trigger) =>
                trigger.modifier ? { ...trigger, modifier: modifier } : trigger
            )
        ]);
        setActiveExitModifier(modifier);
    };

    const validateDuplicatedParams = (conditions: Trigger[]) => {
        for (let i = 0; i < conditions.length; i++) {
            for (let j = 0; j < conditions.length; j++) {
                if (i === j) continue;

                const { modifier: _x, ...firstCondition } = conditions[i];
                const { modifier: _y, ...secondCondition } = conditions[j];

                if (objectEquals(firstCondition, secondCondition)) {
                    return true;
                }
            }
        }
        return false;
    }

    const validateStrategy = (): [pass: boolean, reason?: string] => {
        if (strategyName.length === 0 || (strategyName === "Unnamed Strategy")) {
            return [false, "Please enter a strategy name"]
        } else if (!entryConditions.length) {
            return [false, "Please add an entry condition"]
        } else if (!exitConditions.length) {
            return [false, "Please add an exit condition"]
        }

        for (const condition of [...entryConditions, ...exitConditions]) {
            if (condition.params) {
                for (const param of condition.params) {
                    if (!param.value) {
                        return [false, `Please enter a value for ${param.title}`]
                    }
                }
            }
        }

        if (validateDuplicatedParams(entryConditions)) {
            return [false, `You cannot duplicate Entry params.`]
        }

        if (validateDuplicatedParams(exitConditions)) {
            return [false, `You cannot duplicate Exit params.`]
        }

        return [true];
    }

    const saveStrategy = () => {
        setSavePending(true);
        const newStrategy: IStrategy = {
            name: strategyName,
            id: strategy ? strategy.id : Math.floor(Math.random() * 10000),
            strategy: { entryConditions: entryConditions, exitConditions: exitConditions },
        };

        const [flag, reason] = validateStrategy();
        if (flag) {
            createOrUpdateStrategy(newStrategy, newStrategy.id).then(() => {
                message.info('Strategy saved');
                refreshUser();
                setSavePending(false);
                setSaveValidated(false);
                pushStrategy(newStrategy);
            });
        } else {
            message.info(reason);
            setSavePending(false);
        }

    };

    return (
        <div>
            <PageHeader
                title={
                    <Row>
                        <Col>
                            <span>Strategy Builder</span>
                        </Col>
                        {!quickedit && <>
                            <Col>
                                <Button
                                    shape='round'
                                    type='default'
                                    style={{ marginLeft: 15 }}
                                    onClick={saveStrategy}
                                >
                                    Save {savePending && <LoadingOutlined />}
                                </Button>
                            </Col>
                            <Col style={{ paddingLeft: 10 }}>
                                <Button disabled={saveValidated} type="primary" shape="round" onClick={() => strategy && handleBotBackTest(strategy)}>
                                    Back Test<ArrowRightOutlined />
                                </Button>
                            </Col>
                        </>}
                    </Row>
                }
                onBack={() => setCurrentKey(0)}
            />
            <Row>
                <Col>
                    <h3>
                        {isEditingName ? (
                            <Row>
                                <Col>
                                    <Input
                                        type='text'
                                        autoFocus
                                        onKeyDown={k => {
                                            k.key === 'Enter' && setIsEditingName(false);
                                        }}
                                        onChange={e => setStrategyName(e.target.value)}
                                        value={strategyName}
                                        onBlur={() => setIsEditingName(false)}
                                    />
                                </Col>
                            </Row>
                        ) : (
                            <>
                                {strategyName}
                                {!quickedit &&
                                    <EditOutlined
                                        style={{ fontSize: 14, marginLeft: 5, cursor: 'pointer' }}
                                        onClick={() => setIsEditingName(true)}
                                    />
                                }
                            </>
                        )}
                    </h3>
                </Col>
                <Col style={{ paddingLeft: 30 }} />
            </Row>
            <CollapsibleSection
                title='Entry Conditions'
                open={entryConditionsOpen}
                setOpen={setEntryConditionsOpen}
                setData={setEntryConditions}
                data={entryConditions}
                modifier={entryModifier}
                setModifier={setEntryModifier}
                userStatus={userStatus}
                readonly={readonly}
            />
            <CollapsibleSection
                title='Exit Conditions'
                open={exitConditionsOpen}
                setOpen={setExitConditionsOpen}
                setData={setExitConditions}
                data={exitConditions}
                modifier={exitModifier}
                setModifier={setExitModifier}
                userStatus={userStatus}
                readonly={readonly}

            />

            {quickedit &&  !readonly && <Row style={{paddingTop: 30}}>
                <Button type="primary" shape="round" onClick={saveStrategy}>
                    Save And Test {savePending && <LoadingOutlined />}
                </Button>
            </Row>}
        </div>
    );
};

export default StrategyBuilder;
