import React, { useState } from 'react';
import { Param, Trigger } from '../triggers';
import { Row, Col, Tag, Select } from 'antd';
import { CloseOutlined, PropertySafetyFilled } from '@ant-design/icons';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import reactStringReplace from 'react-string-replace';

const { Option } = Select;

interface ParameterProps {
    param: Param | undefined;
    trigger: Trigger;
    handleParam: (index: number, paramId: string, value: any) => void 
    triggerIndex: number
}

const EditableParam = (props: ParameterProps) => {
    const [editing, setEditing] = useState<boolean>(false);
    const { param, handleParam } = props;

    const renderMod = () => {
        if(param) {
            if(param.type === "number" || param.type === "string") {
                return <input
                    autoFocus
                    type={param.type}
                    onChange={e => {
                        // props.param && props.handleParam(props.param.id, e.target.value, props.triggerIndex)
                        handleParam(props.triggerIndex, param.id, e.target.value)
                        
                    }}
                    style={{ border: 'none', width: 40 }}
                    onKeyDown={e => {
                      e.key === "Enter" && setEditing(false)
                    }}
                    className='editableParam'
                    onBlur={() => setEditing(false)}
                />
            } else if(param.type === "select") {
                return <Select 
                    size="small" 
                    bordered={false}
                    defaultOpen
                    {...param.value && {value: param.value} }
                    autoFocus={true}
                    onSelect={(v: string) => {
                        handleParam(props.triggerIndex, param.id, v)
                        setEditing(v => !v);
                        console.log("fire", editing)
                    }}
                    style={{
                        width: 100
                    }}>
                        {param.options && <>
                        {param.options.map((i) => <Option value={i.value}>{i.label}</Option>)}
                    </>}
                </Select>
            }
        }
    }
    
    return param ? (
        <Tag color='blue' onClick={() => !editing && setEditing(true)}>
            {param.preFix ?? ""} {editing ? (
                <>{renderMod()}</>
            ) : (
                <span>{param.value ? param.value : param.title}</span>
            )}{' '}{param.postFix ?? ""}
        </Tag>
    ) : (
        <></>
    );
};

interface TriggerRowProps {
    trigger: Trigger;
    index: number;
    handleTriggerDelete: (index: number) => void;
    handleParam: (index: number, paramId: string, value: any) => void 
    isDragging: boolean
    setModifier: (modifier: 'or' | 'and') => void;
    readonly?: boolean;
}

const TriggerRow = ({ trigger, handleTriggerDelete, index, handleParam, isDragging, setModifier, readonly }: TriggerRowProps) => {
    const [mouseInside, setMouseInside] = useState(false);

    return (
        <Row
            className='selectedTriggerRow'
            style={{backgroundColor: isDragging ? "#E6F6FF" : "white", padding: 10}}
            onMouseEnter={() => setMouseInside(true)}
            onMouseLeave={() => setMouseInside(false)}
        >
            <Col style={{width: 55 }} >
                <span
                    style={{
                        fontWeight: 700,
                        // transition: 'width 2s, height: 2s;',
                        boxSizing: 'border-box',
                        fontSize: 12,
                        backgroundColor: '#34eb98',
                        marginRight: 9,
                        borderRadius: 4,
                        color: 'white',
                        padding: 7,
                        cursor: 'pointer',
                        width: 30,
                        height: 30,
                    }}
                    onClick={() => {
                        if(trigger.modifier) {
                            if(trigger.modifier === "and") {
                                setModifier("or")
                            } else {
                                setModifier("and")
                            }
                        }
                    }}
                >
                    {trigger.modifier ? trigger.modifier.toUpperCase() : 'IF'}
                </span>
            </Col>
            <Col>
                <span style={{ fontSize: 13 }}>
                    {trigger.params
                        ? reactStringReplace(trigger.inlineTitle, /{(.*?)}/, (match, i) => (
                              <EditableParam
                                  param={
                                      trigger.params &&
                                      trigger.params.find((i: Param) => i.id === match)
                                  }
                                  triggerIndex={index}
                                  handleParam={handleParam}
                                  trigger={trigger}
                              />
                          ))
                        : trigger.inlineTitle}
                </span>
            </Col>
            {!readonly && <Col>
                {mouseInside && (
                    <span className='triggerRowDelete' onClick={() => handleTriggerDelete(index)}>
                        <CloseOutlined style={{ fontSize: 10, width: 14, height: 14 }} />
                    </span>
                )}
            </Col>}
        </Row>
    );
};

interface TriggerListProps {
    selectedTriggers: Array<Trigger>;
    setSelectedTriggers: (triggers: Array<Trigger>) => void;
    activeModifier: "or" | "and"
    setModifier: (modifier: 'or' | 'and') => void;
    readonly?: boolean
}

const TriggerList = (props: TriggerListProps) => {
    const { selectedTriggers, setSelectedTriggers, setModifier, activeModifier } = props;
    
    const handleTriggerDelete = (index: number) => {
        let newSelectedTriggers = selectedTriggers.filter((_, itemIndex) => itemIndex != index); 
        
        if(index === 0 && newSelectedTriggers.length) {
            delete newSelectedTriggers[0]['modifier']
        } 
        
        setSelectedTriggers(newSelectedTriggers);
    };

    const handleTriggerParam = (index: number, paramId: string, value: any) => {
        setSelectedTriggers(selectedTriggers.map((x,y) => 
            y === index ? {
                ...x,
                params: x.params?.map((a) => a.id === paramId ? {...a, value} : a)
            } : x
        ))
    }

    const reorder = (
        list: Array<Trigger>,
        startIndex: number,
        endIndex: number
    ): Array<Trigger> => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, {...removed});

        return [...result];
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        let items: Array<Trigger> = reorder(selectedTriggers, result.source.index, result.destination.index);
        
        for(const trigger of items) {
            trigger['modifier'] = activeModifier
        }
        
        if(items[0]['modifier']) {
            delete items[0]['modifier']
        }


        setSelectedTriggers([...items])
    };

    return (
        <>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId='droppable'>
                    {(provided, snapshot) => (
                        <div {...provided.droppableProps} ref={provided.innerRef}>
                            {selectedTriggers.map((trigger: Trigger, index: number) => (
                                <Draggable key={index} draggableId={index.toString()} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                        >
                                            <TriggerRow
                                            readonly={props.readonly}
                                            setModifier={setModifier}
                                                isDragging={snapshot.isDragging}
                                                trigger={trigger}
                                                handleParam={handleTriggerParam}
                                                index={index}
                                                handleTriggerDelete={handleTriggerDelete}
                                            />
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </>
    );
};

export default TriggerList;
