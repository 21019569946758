export const numberFormatter = (n: number, prefix?: string, postfix?: string): string => {
  return prefix ?? "" + parseFloat(n.toFixed(2)).toLocaleString();
}

export const objectEquals = ( x:any, y:any ) => {
  if ( x === y ) return true;
  if ( ! ( x instanceof Object ) || ! ( y instanceof Object ) ) return false;
  if ( x.constructor !== y.constructor ) return false;
  for ( var p in x ) {
    if ( ! x.hasOwnProperty( p ) ) continue;
    if ( ! y.hasOwnProperty( p ) ) return false;
    if ( x[ p ] === y[ p ] ) continue;
    if ( typeof( x[ p ] ) !== "object" ) return false;
    if ( ! objectEquals( x[ p ],  y[ p ] ) ) return false;
  }

  for ( p in y )
    if ( y.hasOwnProperty( p ) && ! x.hasOwnProperty( p ) )
      return false;
  return true;
} 