import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Select, Form, Input, Button, Tag } from 'antd';
import { useNavigate } from 'react-router';
import { doCreateCheckout, doCreateUser } from '../base';
import RedditPixel from 'react-reddit-pixel';

import blue from '../images/blue.jpg';
import logowhite from '../images/white.png';
const Register: React.FC = () => {
    const navigate = useNavigate();
    const [signupPlan, setSignupPlan] = useState<string>('');
    RedditPixel.init('t2_sxq645lo');

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const plan = urlParams.get('plan');
        if (plan != null) {
            localStorage.setItem('planOfIntent', plan);
            setSignupPlan(plan);
            console.log(plan);
        } else {
            setSignupPlan('free');
        }
    }, []);

    const handleRegistration = useCallback(
        async values => {
            doCreateUser(
                values['username'],
                values['password'],
                values['zipcode'],
                values['firstname'],
                values['lastname']
            ).then(() => {
                RedditPixel.track('Signup');
                if (signupPlan === 'premium') {
                    doCreateCheckout().then(() => {
                        navigate('/');
                    });
                } else {
                    navigate('/');
                }
            });
        },
        [navigate]
    );
    console.log(signupPlan);
    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                backgroundColor: '#F7F7F7'
            }}
        >
            {/* <Header /> */}
            <Row style={{ height: '43%' }}>
                <Col
                    lg={{ span: 12 }}
                    xs={{ span: 0 }}
                    style={{
                        backgroundImage: `url(${blue})`,
                        backgroundPosition: 'center',
                        backgroundSize: 'cover',
                        backgroundRepeat: 'no-repeat',
                        color: 'white'
                    }}
                >
                    <div
                        style={{
                            backgroundColor: 'rgba(24,144,255, 0.8)',
                            padding: 40,
                            height: '100%'
                        }}
                    >
                        <Row>
                            <Col>
                                <img src={logowhite} height={60} />
                            </Col>
                        </Row>
                        <Row style={{ paddingTop: 70 }}>
                            <h1 style={{ color: 'white', fontSize: 60 }}>Create Your Login</h1>
                        </Row>
                        <Row>
                            <span>
                                Build strategies and discover alpha without risking capital.
                            </span>
                        </Row>
                    </div>
                </Col>
                <Col
                    lg={{ span: 12 }}
                    xs={{ span: 24, offset: 0 }}
                    style={{
                        padding: 45,
                        backgroundColor: 'white'
                    }}
                >
                    <Form
                        name='basic'
                        // labelCol={{ span: 8 }}
                        // wrapperCol={{ span: 16 }}
                        initialValues={{ remember: true }}
                        onFinish={handleRegistration}
                        // onFinishFailed={onFinishFailed}
                        autoComplete='off'
                    >
                        <Form.Item>
                            {/* <img src={logo} height={60} /> */}
                            <h2>Register for Fulcher Analytics</h2>
                        </Form.Item>
                        {/* <Form.Item wrapperCol={{ offset: 5, span: 16 }} style={{ paddingLeft: 10 }}>
                            <img src={logo} height={60} />
                        </Form.Item> */}
                        <small>First Name</small>
                        <Form.Item
                            name='firstname'
                            rules={[{ required: true, message: 'Please enter your first name' }]}
                        >
                            <Input width={200} />
                        </Form.Item>

                        <small>Last Name</small>
                        <Form.Item
                            name='lastname'
                            rules={[{ required: true, message: 'Please enter your last name' }]}
                        >
                            <Input width={200} />
                        </Form.Item>

                        <small>Zip Code</small>
                        <Form.Item
                            name='zipcode'
                            rules={[{ required: false, message: 'Please enter your zip code' }]}
                        >
                            <Input type='number' width={200} />
                        </Form.Item>

                        <small>Email</small>
                        <Form.Item
                            name='username'
                            rules={[{ required: true, message: 'Please enter your email' }]}
                        >
                            <Input type='email' width={200} />
                        </Form.Item>

                        <small>Plan</small>
                        <Form.Item name='plan'>
                            {signupPlan && (
                                <Select
                                    defaultValue={signupPlan}
                                    onSelect={(i: string) => setSignupPlan(i)}
                                    value={signupPlan}
                                    options={[
                                        {
                                            value: 'free',
                                            label: 'Fulcher Analytics Free'
                                        },
                                        {
                                            value: 'premium',
                                            label: (
                                                <span>
                                                    Fulcher Analytics Premium ($29/mo){' '}
                                                    <Tag color='blue'>Recommended</Tag>
                                                </span>
                                            )
                                        }
                                    ]}
                                />
                            )}
                        </Form.Item>

                        <small>Password</small>
                        <Form.Item
                            name='password'
                            rules={[{ required: true, message: 'Please enter your password' }]}
                        >
                            <Input.Password width={200} />
                        </Form.Item>

                        <small>
                            <a href='/login'>Already have an account? Login</a>
                        </small>

                        <Form.Item>
                            <Button
                                type='primary'
                                htmlType='submit'
                                style={{ marginTop: 20, width: 100 }}
                                // onClick={() => setStatus(true)}
                            >
                                Next
                                {/* {status && <LoadingOutlined />} */}
                            </Button>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </div>
    );
};

export default Register;
